import React, { useCallback, useMemo } from "react";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useCompany } from "hooks/useCompany";
import { AdyenTerminalPaymentMethodTypeEnum } from "types/graphql";

import { DetailTypeTable } from "./DetailTypeTable";
import {
  useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery,
  useGetOnSitePaymentDetailTypesQuery,
  useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation,
  useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation,
} from "./queries";

export const AdyenTerminalOnSitePaymentDetailTypes = () => {
  const [company] = useCompany();
  const companyId = company?.id;

  const {
    data,
    loading: loadingAdyenTerminalPaymentOnSitePaymentDetailTypes,
    refetch: refetchAdyenTerminalPaymentOnSitePaymentDetailTypes,
  } = useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery(
    companyId ? { variables: { companyId } } : { skip: true },
  );

  const { data: onSitePaymentDetailTypesData, loading: loadingOnSitePaymentDetailTypesData } =
    useGetOnSitePaymentDetailTypesQuery(companyId ? { variables: { companyId } } : { skip: true });

  const [updateAdyenTerminalPaymentOnSitePaymentDetailType] =
    useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation();

  const [deleteAdyenTerminalPaymentOnSitePaymentDetailType] =
    useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation();

  const updateDetailType = useCallback(
    async ({
      adyenTerminalPaymentMethodType,
      onSitePaymentDetailTypeId,
    }: {
      adyenTerminalPaymentMethodType: AdyenTerminalPaymentMethodTypeEnum;
      onSitePaymentDetailTypeId: string | undefined;
    }) => {
      if (!companyId || !onSitePaymentDetailTypeId) return;

      try {
        await updateAdyenTerminalPaymentOnSitePaymentDetailType({
          variables: {
            companyId,
            adyenTerminalPaymentMethodType,
            objects: [
              {
                companyId,
                onSitePaymentDetailTypeId,
                adyenTerminalPaymentMethodType,
              },
            ],
          },
        });

        refetchAdyenTerminalPaymentOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      companyId,
      updateAdyenTerminalPaymentOnSitePaymentDetailType,
      refetchAdyenTerminalPaymentOnSitePaymentDetailTypes,
    ],
  );

  const deleteDetailType = useCallback(
    async (adyenTerminalPaymentMethodType: AdyenTerminalPaymentMethodTypeEnum) => {
      if (!companyId) return;

      try {
        await deleteAdyenTerminalPaymentOnSitePaymentDetailType({
          variables: { companyId, adyenTerminalPaymentMethodType },
        });

        refetchAdyenTerminalPaymentOnSitePaymentDetailTypes();

        message.success("編集を保存しました");
      } catch (e) {
        message.error("編集の保存に失敗しました");
      }
    },
    [
      companyId,
      refetchAdyenTerminalPaymentOnSitePaymentDetailTypes,
      deleteAdyenTerminalPaymentOnSitePaymentDetailType,
    ],
  );

  const adyenTerminalPaymentOnSitePaymentDetailTypes = useMemo(
    () => data?.adyenTerminalPaymentOnSitePaymentDetailType ?? [],
    [data?.adyenTerminalPaymentOnSitePaymentDetailType],
  );

  const onSitePaymentDetailTypes = useMemo(
    () => onSitePaymentDetailTypesData?.onSitePaymentDetailType ?? [],
    [onSitePaymentDetailTypesData],
  );

  const loading =
    loadingAdyenTerminalPaymentOnSitePaymentDetailTypes || loadingOnSitePaymentDetailTypesData;

  return (
    <DashboardLayout title="ダイニーキャッシュレス端末 支払方法設定">
      <PageHeader title="ダイニーキャッシュレス端末 支払方法設定" />
      <DetailTypeTable
        adyenTerminalPaymentOnSitePaymentDetailTypes={adyenTerminalPaymentOnSitePaymentDetailTypes}
        onSitePaymentDetailTypes={onSitePaymentDetailTypes}
        loading={loading}
        onUpdate={updateDetailType}
        onDelete={deleteDetailType}
      />
    </DashboardLayout>
  );
};
