import React, { memo } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TitleStyle = styled.h3<{ fontSize: number; fontWeight: string }>`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: 22px;
  max-height: 66px;
  overflow: hidden;
  margin-bottom: 0;
`;

type Props = {
  title: string;
  fontSize: number;
  fontWeight: string;
};
export const Title = memo<Props>(({ title, fontSize, fontWeight }) => (
  <Wrapper>
    <TitleStyle fontWeight={fontWeight} fontSize={fontSize}>
      {title}
    </TitleStyle>
  </Wrapper>
));
