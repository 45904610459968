import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPikaichiMenuFormItem } from "../useEditPikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiBumonCdField = memo<Props>((props) => (
  <EditPikaichiMenuFormItem
    label="部門コード"
    name="pikaichiBumonCd"
    rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditPikaichiMenuFormItem>
));
