import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPikaichiMenuFormItem } from "../useEditPikaichiMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const PikaichiBumonNameField = memo<Props>((props) => (
  <EditPikaichiMenuFormItem
    label="部門名"
    name="pikaichiBumonName"
    rules={[{ max: 15, message: "15文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditPikaichiMenuFormItem>
));
