import { useCallback, useMemo } from "react";
import { uniq } from "util/array";

import {
  useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery,
  useAdyenPaymentDepositHistoryGetShopsQuery,
} from "./queries";

export type AdyenPaymentRemittanceResultResponse = NonNullable<
  ReturnType<typeof useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery>["data"]
>;

export const useAdyenPaymentDepositHistoryByShop = ({
  remittanceResultId,
  onFetchCompleted,
}: {
  remittanceResultId: string | undefined;
  onFetchCompleted: ({ shopIds }: { shopIds: string[] }) => void;
}) => {
  const {
    data,
    loading: loadingRemittanceResult,
    error: getRemittanceResultError,
  } = useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery(
    remittanceResultId
      ? {
          variables: {
            remittanceResultId,
          },
          onCompleted: (data) => {
            const shopIds = data.adyenPaymentRemittanceResult.flatMap((remittance) =>
              remittance.adyenPaymentRemittanceClosingDeposits.map(
                (deposit) => deposit.adyenPaymentClosingDeposit.shopId,
              ),
            );
            onFetchCompleted({ shopIds: uniq(shopIds) });
          },
        }
      : { skip: true },
  );

  const depositByShop = useMemo(() => {
    const adyenPaymentRemittanceResults = data?.adyenPaymentRemittanceResult ?? [];
    // NOTE: 各送金 = adyenPaymentRemittanceResult をもとに、店舗ごとの送金情報 = adyenPaymentClosingDeposit を取得する
    // 店舗ごとの送金情報に、送金結果情報 = gmoBankAccount, remittanceStatus, remittedAt も付与する
    return adyenPaymentRemittanceResults.flatMap((result) =>
      result.adyenPaymentRemittanceClosingDeposits.map((closingDeposit) => ({
        ...closingDeposit.adyenPaymentClosingDeposit,
        gmoBankAccountId: result.gmoBankAccountId,
        remittanceStatus: result.remittanceStatus,
        remittedAt: result.remittedAt,
        remittanceResultId: result.id,
      })),
    );
  }, [data]);

  const gmoBankAccountIds = useMemo(
    () => depositByShop.map(({ gmoBankAccountId }) => gmoBankAccountId),
    [depositByShop],
  );

  return {
    depositByShop,
    loading: loadingRemittanceResult,
    error: getRemittanceResultError,
    gmoBankAccountIds,
  };
};

export const useShopDetails = ({ shopIds }: { shopIds: string[] }) => {
  const { data, loading, error } = useAdyenPaymentDepositHistoryGetShopsQuery({
    variables: {
      shopIds,
    },
  });

  const getShopDetail = useCallback(
    ({ shopId }: { shopId: string }) => data?.shop.find((shop) => shop.shopId === shopId),
    [data],
  );

  return {
    getShopDetail,
    loading,
    error,
  };
};
