import React, { memo } from "react";
import { Link } from "react-router-dom";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

type Props = {
  loading?: boolean;
  gmoBankAccounts: { name: string; id: string }[];
};

export const GmoBankAccountTable = memo<Props>(({ loading, gmoBankAccounts }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "管理名",
      dataIndex: "name",
      render(_: unknown, { name }: (typeof gmoBankAccounts)[number]) {
        return name;
      },
    },
    {
      title: "",
      align: "center",
      width: 60,
      render(_: unknown, { id }: (typeof gmoBankAccounts)[number]) {
        return (
          <Link to={`/gmoBankAccount/${id}/edit`}>
            <EditIcon />
          </Link>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={gmoBankAccounts}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
