import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal, Typography } from "antd";
import { isEmptyObject } from "util/object";

import { AssignShopCashlessSettingTerminalIdFormValues } from "../useAssignShopCashlessSettingTerminalIdForm";

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
`;

type Props = {
  visible: boolean;
  getFieldsValue: () => AssignShopCashlessSettingTerminalIdFormValues;
  onCancel: () => void;
  onConfirm: () => void;
};

export const AssignShopCashlessSettingTerminalIdFormConfirmModal = memo<Props>(
  ({ visible, getFieldsValue, onCancel, onConfirm }) => {
    const fieldsValue = getFieldsValue();

    if (!fieldsValue || isEmptyObject(fieldsValue)) return null;

    const { model, serialNumber } = fieldsValue;

    return (
      <Modal
        title={<Title>変更内容確認</Title>}
        zIndex={1002}
        centered
        closable={false}
        open={visible}
        footer={
          <>
            <Button onClick={onCancel}>キャンセル</Button>
            <Button type="primary" onClick={onConfirm}>
              登録
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <Typography.Text>
          更新すると、端末の設定が上書きされます。
          <br />
          更新しますか？
          <br />
          <br />
          更新する端末: {model}-{serialNumber}
        </Typography.Text>
      </Modal>
    );
  },
);
