import React, { memo, useCallback, useMemo } from "react";
import { Col, Row, Select } from "antd";
import { uniq } from "lodash";
import { isNotNullable } from "util/type/primitive";

import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

export type FilterConditions = {
  shopIds?: string[];
};

type Props = {
  shopIds: string[];
  getShopDetail: ({ shopId }: { shopId: string }) => { shopId: string; name: string } | undefined;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
};

export const AdyenPaymentDepositFilter = memo<Props>(
  ({ shopIds, getShopDetail, filterConditions, updateFilterCondition }) => {
    const handleUpdateFilterCondition = useCallback<
      UpdateFilterConditionFunctionType<FilterConditions>
    >((values) => updateFilterCondition(values), [updateFilterCondition]);

    const shopOptions = useMemo(
      () =>
        uniq(shopIds)
          .map((shopId) => {
            const detail = getShopDetail({ shopId });
            if (!detail) {
              return null;
            }
            return {
              label: detail.name,
              value: detail.shopId,
            };
          })
          .filter(isNotNullable),
      [shopIds, getShopDetail],
    );

    return (
      <Row>
        <Col span={24}>
          <Select<string[]>
            allowClear
            mode="multiple"
            placeholder="店舗を選択"
            value={filterConditions.shopIds}
            onChange={(shopIds) => handleUpdateFilterCondition({ shopIds })}
            optionFilterProp="label"
            options={shopOptions}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    );
  },
);
