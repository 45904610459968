import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useBankCode } from "hooks/useBankCode";

import { AddGmoBankAccountFormValues } from "./AddGmoBankAccountForm/useAddGmoBankAccountForm";
import { AddGmoBankAccountForm } from "./AddGmoBankAccountForm";
import {
  useAddGmoBankAccountCreateAccountMutation,
  useAddGmoBankAccountGetShopsByCompanyQuery,
  useEditGmoBankAccountGetCorporationQuery,
} from "./queries";

export const AddGmoBankAccount = () => {
  const {
    bankCodes,
    branchCodes,
    fetchBranchCodeByBankCode,
    loading: loadingBankCode,
    error: getBankCodeError,
  } = useBankCode();

  const {
    data: shopsData,
    error: getShopsError,
    loading: loadingShopsData,
  } = useAddGmoBankAccountGetShopsByCompanyQuery();

  const shopsByCompany = useMemo(
    () => (shopsData?.company ?? []).filter((item) => item.shops.length > 0),
    [shopsData?.company],
  );

  const {
    data: corporationsData,
    error: getCorporationError,
    loading: loadingCorporationData,
  } = useEditGmoBankAccountGetCorporationQuery();

  const corporations = useMemo(() => corporationsData?.corporation ?? [], [corporationsData]);
  const [createGmoBankAccountMutation, { loading: loadingCreateGmoBankAccount }] =
    useAddGmoBankAccountCreateAccountMutation();

  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (formValues: AddGmoBankAccountFormValues) => {
      if (!formValues.accountType || !formValues.corporationId) return;

      try {
        const { errors } = await createGmoBankAccountMutation({
          variables: {
            input: {
              accountName: formValues.accountHolder,
              accountNumber: formValues.accountNumber,
              accountType: formValues.accountType,
              bankCode: formValues.bank,
              branchCode: formValues.branch,
              name: formValues.name,
              depositCycleShopIds: formValues.depositCycleShopIds,
              corporationId: formValues.corporationId,
            },
          },
        });
        if (errors) {
          return message.error("口座の登録に失敗しました");
        }
        message.success("口座の登録に成功しました");
        navigate(-1);
      } catch (e) {
        message.error("アカウントの作成に失敗しました");
      }
    },
    [createGmoBankAccountMutation, navigate],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  const loading =
    loadingBankCode || loadingShopsData || loadingCreateGmoBankAccount || loadingCorporationData;
  const shouldShowAlert = getShopsError ?? getBankCodeError ?? getCorporationError;

  return (
    <DashboardLayout title="法人口座の新規登録">
      {shouldShowAlert && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddGmoBankAccountForm
        onSubmit={onSubmit}
        onFormValidationError={onFormValidationError}
        fetchBranchCode={fetchBranchCodeByBankCode}
        loading={loading}
        shopsByCompany={shopsByCompany}
        corporations={corporations}
        banks={bankCodes}
        branches={branchCodes}
      />
    </DashboardLayout>
  );
};
