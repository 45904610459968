import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useWatch } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { sleep } from "util/sleep";

import { Loading } from "components/Loading";
import { grey } from "constants/colors";

import { MobileOrderDesignSettingsFormValues } from ".";

const SCALE = 1.6;

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: calc(${({ width }) => width}px * ${SCALE});
  height: calc(${({ height }) => height}px * ${SCALE});
`;

const LoadingOverlay = styled.div<{ width: number; height: number }>`
  position: absolute;
  width: calc(${({ width }) => width}px * ${SCALE});
  height: calc(${({ height }) => height}px * ${SCALE});
  padding: 0;
  margin: 0;
  transform: scale(${() => 1 / SCALE}) translate(-144px, -305px);
  top: 0;
  left: 0;
  background-color: ${grey[4]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIframe = styled.iframe<{ width: number; height: number }>`
  border: none;
  pointer-events: none;
  width: calc(${({ width }) => width}px * ${SCALE});
  height: calc(${({ height }) => height}px * ${SCALE});
  padding: 0;
  margin: 0;
  transform: scale(${() => 1 / SCALE}) translate(-144px, -305px);
`;

type Props = {
  form: FormInstance<MobileOrderDesignSettingsFormValues>;
  shopName: string | null;
  isDarkThemeEnabled: boolean;
  title: string;
  src: string;
  width: number;
  height: number;
};

type WelcomeScreenPreviewIframePayload =
  | {
      shopName: string;
    }
  | {
      welcomeMessage: string | null;
    }
  | {
      backgroundImgSrc: string | null;
    }
  | {
      isDarkThemeEnabled: boolean;
    };

const postIframeMessage = ({
  iframe,
  payload,
}: {
  iframe: HTMLIFrameElement;
  payload: WelcomeScreenPreviewIframePayload;
}) => {
  iframe.contentWindow?.postMessage(JSON.stringify(payload), "*");
};

export const WelcomeScreenPreviewIframe = ({
  form,
  shopName,
  isDarkThemeEnabled,
  title,
  src,
  width,
  height,
}: Props) => {
  const welcomeMessage: MobileOrderDesignSettingsFormValues["welcomeMessage"] = useWatch(
    "welcomeMessage",
    form,
  );
  const backgroundImgSrc: MobileOrderDesignSettingsFormValues["backgroundImgSrc"] = useWatch(
    "backgroundImgSrc",
    form,
  );

  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const messageQueueRef = useRef<WelcomeScreenPreviewIframePayload[]>([]);

  const handleIframeLoaded = useCallback(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    (async () => {
      /**
       * NOTE: there is a chance that the preview page's listeners aren't ready, so we will wait for a little time
       * We could wait for a message from the iFrame, but for security reasons it's better to not
       */
      await sleep(1000);

      postIframeMessage({
        iframe,
        payload: {
          welcomeMessage,
        },
      });

      postIframeMessage({
        iframe,
        payload: {
          backgroundImgSrc,
        },
      });

      if (!shopName) return;
      postIframeMessage({
        iframe,
        payload: {
          shopName,
        },
      });

      setIsIframeLoading(false);
    })();
  }, [welcomeMessage, backgroundImgSrc, shopName]);

  const queueOrSendMessage = useCallback(
    (payload: WelcomeScreenPreviewIframePayload) => {
      if (iframeRef.current && !isIframeLoading) {
        postIframeMessage({
          iframe: iframeRef.current,
          payload,
        });
        return;
      }

      // NOTE: if the iframe is not yet loaded, queue the data for later
      messageQueueRef.current.push(payload);
    },
    [isIframeLoading],
  );

  useEffect(() => {
    if (!shopName) return;
    queueOrSendMessage({
      shopName,
    });
  }, [queueOrSendMessage, shopName]);

  useEffect(() => {
    queueOrSendMessage({
      isDarkThemeEnabled,
    });
  }, [queueOrSendMessage, isDarkThemeEnabled]);

  useEffect(() => {
    queueOrSendMessage({
      welcomeMessage,
    });
  }, [queueOrSendMessage, welcomeMessage]);

  useEffect(() => {
    queueOrSendMessage({
      backgroundImgSrc,
    });
  }, [queueOrSendMessage, backgroundImgSrc]);

  return (
    <Container width={width} height={height}>
      <StyledIframe
        ref={iframeRef}
        title={title}
        src={src}
        width={width}
        height={height}
        onLoad={handleIframeLoaded}
      />
      {isIframeLoading ? (
        <LoadingOverlay width={width} height={height}>
          <Loading size="large" />
        </LoadingOverlay>
      ) : null}
    </Container>
  );
};
