import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const WinboardMenu = memo<Props>(({ company, ...props }) => {
  const { isFeatureEnabled } = useIsFeatureEnabled();
  const isShowConfig = isFeatureEnabled("showWinboardConfig");

  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const { isFeatureAvailable } = useFeatureFlag();
  const canShowYellMenu = isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds });

  return (
    <SubMenu
      key="winboard"
      title="ウィンボード連携"
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      {isShowConfig && <MenuItem route="editWinboardConfig" to="/winboard/config" text="設定" />}
      <MenuItem route="winboardUpload" to="/winboard/upload" text="連携処理" />
      <MenuItem
        route="winboardMenuRegistrationCsv"
        to="/winboard/menuRegistrationCsv"
        text="商品登録CSV"
      />
      <MenuItem route="winboardInflowSourceTags" to="/winboard/inflowSourceTag" text="媒体設定" />
      <MenuItem route="winboardMenus" to="/winboard/menu" text="メニュー" />
      <MenuItem route="winboardPlans" to="/winboard/plan" text="プラン" />
      <MenuItem
        route="winboardMenus"
        to="/winboard/gift"
        text="推しエール"
        canShow={canShowYellMenu}
      />
      <MenuItem route="menuCodeCsv" to="/menuCodeCsv/winboard" text="メニューコード一括編集" />
    </SubMenu>
  );
});
