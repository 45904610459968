type Fragment = {
  type: "text" | "url" | "lineBreak";
  value?: string;
};
/**
 * コンポーネント側で「テキスト」「URL」「改行」をそれぞれ異なるHTMLタグで表示できるようにするため
 * ラベルを付けたオブジェクトの配列を生成する。
 */
export const parseTextsAndUrls = (input: string): Fragment[] => {
  const urlPattern =
    /https:\/\/(www\.)?[?a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*/g;
  const fragments: Fragment[] = [];
  const lines = input.split("\n");

  lines.forEach((line, i) => {
    const currentLine = line ?? "";
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = urlPattern.exec(currentLine)) !== null) {
      if (match.index > lastIndex) {
        fragments.push({ type: "text", value: currentLine.slice(lastIndex, match.index) });
      }
      fragments.push({ type: "url", value: match[0] });
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < currentLine.length) {
      fragments.push({ type: "text", value: currentLine.slice(lastIndex) });
    }

    if (i < lines.length - 1) {
      if (!lines[i + 1] && lines[i + 1]?.trim() === "") {
        fragments.push({ type: "lineBreak" });
        return;
      }
    }
  });
  return fragments.filter(({ value }) => value?.trim() !== "");
};
