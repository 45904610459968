import React, { memo, useMemo } from "react";
import { Popconfirm } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";
import { adyenTerminalPaymentMethodTypeDetails } from "models/adyenOffPaymentMethodType";

import { DeleteIcon } from "components/ColorIcon/DeleteIcon";
import { Select } from "components/Input/Select";
import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";
import { AdyenTerminalPaymentMethodTypeEnum } from "types/graphql";

import { AdyenTerminalPaymentOnSitePaymentDetailType, OnSitePaymentDetailType } from "../types";

type Props = {
  adyenTerminalPaymentOnSitePaymentDetailTypes: AdyenTerminalPaymentOnSitePaymentDetailType[];
  onSitePaymentDetailTypes: OnSitePaymentDetailType[];
  loading: boolean;
  onUpdate: ({
    adyenTerminalPaymentMethodType,
    onSitePaymentDetailTypeId,
  }: {
    adyenTerminalPaymentMethodType: AdyenTerminalPaymentMethodTypeEnum;
    onSitePaymentDetailTypeId: string | undefined;
  }) => void;
  onDelete: (toDeleteId: AdyenTerminalPaymentMethodTypeEnum) => void;
};

type DataSource = {
  adyenTerminalPaymentMethodType: AdyenTerminalPaymentMethodTypeEnum;
  selectedOnSitePaymentDetailTypeId: string | undefined;
};

export const DetailTypeTable = memo<Props>(
  ({
    adyenTerminalPaymentOnSitePaymentDetailTypes,
    onSitePaymentDetailTypes,
    loading,
    onDelete,
    onUpdate,
  }) => {
    const [pagination, setPagination] = usePagination();

    const dataSource = useMemo<DataSource[]>(
      () =>
        Object.values(AdyenTerminalPaymentMethodTypeEnum).map((adyenTerminalPaymentMethodType) => ({
          adyenTerminalPaymentMethodType,
          selectedOnSitePaymentDetailTypeId: adyenTerminalPaymentOnSitePaymentDetailTypes.find(
            (adyenTerminalPaymentOnSitePaymentDetailType) =>
              adyenTerminalPaymentOnSitePaymentDetailType.adyenTerminalPaymentMethodType ===
              adyenTerminalPaymentMethodType,
          )?.onSitePaymentDetailTypeId,
        })),
      [adyenTerminalPaymentOnSitePaymentDetailTypes],
    );

    const columns: ColumnsType<DataSource> = useMemo(
      () => [
        {
          title: "ダイニーキャッシュレス端末 支払方法",
          width: 300,
          fixed: "left",
          render: (_, { adyenTerminalPaymentMethodType }) =>
            adyenTerminalPaymentMethodTypeDetails[adyenTerminalPaymentMethodType].label,
        },
        {
          title: "支払方法",
          fixed: "left",
          render(_, { adyenTerminalPaymentMethodType, selectedOnSitePaymentDetailTypeId }) {
            return (
              <Select
                value={selectedOnSitePaymentDetailTypeId}
                options={onSitePaymentDetailTypes.map(({ label, id }) => ({ label, value: id }))}
                onChange={(onSitePaymentDetailTypeId) =>
                  onUpdate({
                    adyenTerminalPaymentMethodType,
                    onSitePaymentDetailTypeId,
                  })
                }
                fullWidth
                showSearch
                optionFilterProp="label"
              />
            );
          },
        },
        {
          title: "",
          align: "center",
          width: 60,
          render(_, { adyenTerminalPaymentMethodType, selectedOnSitePaymentDetailTypeId }) {
            const disabled = !selectedOnSitePaymentDetailTypeId;
            return (
              <Popconfirm
                title={
                  <>
                    <Paragraph>ダイニーキャッシュ端末支払方法設定を削除しますか？</Paragraph>
                    <Paragraph>
                      一度削除したダイニーキャッシュレス端末支払方法設定を元に戻すことはできません。
                    </Paragraph>
                  </>
                }
                okText="はい"
                cancelText="キャンセル"
                onConfirm={() => onDelete(adyenTerminalPaymentMethodType)}
                disabled={disabled}
              >
                <DeleteIcon disabled={disabled} />
              </Popconfirm>
            );
          },
        },
      ],
      [onSitePaymentDetailTypes, onUpdate, onDelete],
    );

    return (
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        loading={loading}
        pagination={pagination}
        onChange={({ position: _, ...pagination }) => setPagination(pagination)}
      />
    );
  },
);
