import React, { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Form } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { PageHeader } from "components/antd/PageHeader";
import { FormActions } from "components/Form/FormActions";
import { FormSection } from "components/Form/FormSection";
import { Spacer } from "components/Spacer";
import { BankCode, BranchCode } from "hooks/useBankCode";
import { AccountHolderField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/AccountHolderField";
import { AccountNumberField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/AccountNumberField";
import { AccountTypeField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/AccountTypeField";
import { BankField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/BankField";
import { BranchField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/BranchField";
import { ShopField } from "pages/AddGmoBankAccount/AddGmoBankAccountForm/ShopField";
import { Company, Corporation, Shop } from "types/graphql";

import { CorporationField } from "./CorporationField";
import { NameField } from "./NameField";
import {
  ACCOUNT_TYPES,
  AddGmoBankAccountFormValues,
  useAddGmoBankAccountForm,
} from "./useAddGmoBankAccountForm";

const Wrapper = styled.div`
  width: 640px;
  margin: 0 auto;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

type Props = {
  onSubmit: (formValues: AddGmoBankAccountFormValues) => void;
  onFormValidationError: (args: { formValidationError: ValidateErrorEntity }) => void;
  fetchBranchCode: (args: { bankCode: string }) => void;
  loading: boolean;
  shopsByCompany: (Pick<Company, "name"> & { shops: Pick<Shop, "shopId" | "name">[] })[];
  corporations: Pick<Corporation, "id" | "name">[];
  banks: BankCode[];
  branches: BranchCode[];
};

export const AddGmoBankAccountForm = memo<Props>(
  ({
    onSubmit,
    onFormValidationError,
    fetchBranchCode,
    loading,
    shopsByCompany,
    corporations,
    banks,
    branches,
  }) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const { form, initialValues, submit } = useAddGmoBankAccountForm({ onSubmit });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [form, onFormValidationError, submit]);

    const onFormValuesChange = useCallback(
      (changeValue: { bank: string }) => {
        const { bank } = changeValue;
        if (bank && typeof bank === "string") {
          form.setFieldValue("branch", "");
          fetchBranchCode({ bankCode: bank });
        }
      },
      [form, fetchBranchCode],
    );

    const hasBank = Boolean(form.getFieldValue("bank"));

    return (
      <Wrapper>
        <PageHeader title="法人口座の新規登録" onBack={goBack} />
        <StyledForm
          name="addGmoBankAccount"
          form={form}
          layout="vertical"
          initialValues={initialValues}
          onValuesChange={onFormValuesChange}
        >
          <FormSection>
            <NameField />
            <BankField banks={banks} />
            <BranchField disabled={!hasBank} branches={branches} />
            <AccountTypeField disabled={!hasBank} accountTypes={ACCOUNT_TYPES} />
            <AccountNumberField />
            <AccountHolderField />
            <CorporationField corporations={corporations} />
            <ShopField shopsByCompany={shopsByCompany} />
          </FormSection>
        </StyledForm>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={goBack}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            作成
          </Button>
        </FormActions>
      </Wrapper>
    );
  },
);
