import React, { memo } from "react";
import styled from "styled-components";
import { Button, Layout } from "antd";
import { blue } from "@ant-design/colors";
import {
  CaretDownOutlined,
  CloseOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { UserMenuDropdown } from "components/Layout/DashboardLayout/AppBar/UserMenuDropdown";
import {
  AccessibleCompanies,
  AccessibleShops,
  Company,
} from "components/Layout/DashboardLayout/types";
import { grey } from "constants/colors";
import { helpPageUrl } from "constants/externalLinks";
import { useCompanySelectOverlay } from "hooks/useCompanySelectOverlay";
import { useViewport } from "hooks/useViewport";

import { Spacer as SpaceWidth } from "../../../Spacer";

import { NotificationContainer } from "./Notification";

const { Header } = Layout;

const StyledHeader = styled(Header)`
  display: flex;
  align-items: center;
  padding: 0 32px;
  line-height: normal;
  justify-content: space-between;
  z-index: 1001;
`;

const Logo = styled.div`
  margin-right: 28px;
  cursor: pointer;
  color: ${grey[0]};
`;

const CaretDown = styled(CaretDownOutlined)`
  color: ${grey[0]};
  margin-left: 8px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const HelpIcon = styled(QuestionCircleOutlined)`
  height: 26px;
  font-size: 26px;
`;

const HelpButton = styled(Button)`
  margin-right: 16px;
  display: flex;
  align-items: center;
  color: white;
  padding: 0;
  &:hover {
    color: ${grey[5]};
  }
  &:focus {
    color: white;
    border-color: ${blue[5]};
  }
`;

const Menu = styled(MenuOutlined)`
  color: ${grey[0]};
`;

const Close = styled(CloseOutlined)`
  color: ${grey[0]};
`;

type Props = {
  title: string | undefined;
  accountId: string | undefined;
  accountName: string | undefined;
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  companies: Company[];
  signOut: () => void;
  isOpenDrawer: boolean;
  onOpenDrawer: () => void;
  onCloseDrawer: () => void;
};

export const AppBar = memo<Props>(
  ({
    title,
    accountName,
    accountAccessibleCompanies,
    accountAccessibleShops,
    companies,
    signOut,
    isOpenDrawer,
    onOpenDrawer,
    onCloseDrawer,
    accountId,
  }) => {
    const selectionEnabled = companies.length > 1;

    const { isDesktop } = useViewport();

    const { setCompanySelectOverlayVisible } = useCompanySelectOverlay();
    return (
      <StyledHeader>
        {isDesktop ? (
          <>
            <Logo
              onClick={selectionEnabled ? () => setCompanySelectOverlayVisible(true) : undefined}
            >
              {title}
              {selectionEnabled && <CaretDown />}
            </Logo>
            <Spacer />
            <HelpButton target="_blank" rel="noopener" href={helpPageUrl} type="link">
              <HelpIcon />
              ヘルプサイト
            </HelpButton>
            <UserMenuDropdown userName={accountName} signOut={signOut} />
            <SpaceWidth width={16} />
          </>
        ) : !isOpenDrawer ? (
          <Menu onClick={onOpenDrawer} />
        ) : (
          <Close onClick={onCloseDrawer} />
        )}
        <NotificationContainer
          accountAccessibleCompanies={accountAccessibleCompanies}
          accountAccessibleShops={accountAccessibleShops}
          accountId={accountId}
        />
      </StyledHeader>
    );
  },
);
