import React from "react";
import styled from "styled-components";
import { Alert } from "antd";
import { convertToRanking } from "models/customerDashboard";

import { CustomerRanking } from "components/CustomerRanking";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";

import { useRankingViralCountsGetCustomerViralCountRankingQuery } from "./queries";

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`;

export const RankingViralCounts = () => {
  const { shop } = useShopPerCorporation();

  const {
    data: getCustomerViralCountRankingData,
    loading: getCustomerViralCountRankingDataLoading,
    error: getCustomerViralCountRankingDataError,
  } = useRankingViralCountsGetCustomerViralCountRankingQuery(
    shop ? { variables: { input: { shopId: shop.shopId } } } : { skip: true },
  );

  const viralRankingData = convertToRanking({
    customers: getCustomerViralCountRankingData?.customerViralCountRanking.customers,
    currentPage: undefined,
    pageSize: undefined,
  });

  return (
    <DashboardLayout title="アンバサダーランキング">
      {getCustomerViralCountRankingDataError && (
        <StyledAlert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CustomerRanking
        rankingData={viralRankingData}
        loading={getCustomerViralCountRankingDataLoading}
        title="アンバサダーランキング"
        valueUnit="人"
      />
    </DashboardLayout>
  );
};
