import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResult = gql`
    query AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResult($remittanceResultId: uuid!) {
  adyenPaymentRemittanceResult(where: {id: {_eq: $remittanceResultId}}) {
    id
    remittedAt
    remittanceStatus
    remittanceAmount
    depositId
    gmoBankAccountId
    adyenPaymentRemittanceClosingDeposits {
      adyenPaymentClosingDeposit {
        id
        shopId
        totalAmount
        totalFeeAmount
        totalNetAmount
        payoutJobSchedule {
          id
          startingDate
          closingDate
          depositCycle
        }
      }
    }
  }
}
    `;
export const AdyenPaymentDepositHistoryGetShops = gql`
    query AdyenPaymentDepositHistoryGetShops($shopIds: [uuid!]!) {
  shop(where: {shopId: {_in: $shopIds}}) {
    shopId
    name
  }
}
    `;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables = Types.Exact<{
  remittanceResultId: Types.Scalars['uuid'];
}>;


export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentRemittanceResult: Array<(
    { __typename?: 'adyenPaymentRemittanceResult' }
    & Pick<Types.AdyenPaymentRemittanceResult, 'id' | 'remittedAt' | 'remittanceStatus' | 'remittanceAmount' | 'depositId' | 'gmoBankAccountId'>
    & { adyenPaymentRemittanceClosingDeposits: Array<(
      { __typename?: 'adyenPaymentRemittanceClosingDeposit' }
      & { adyenPaymentClosingDeposit: (
        { __typename?: 'adyenPaymentClosingDeposit' }
        & Pick<Types.AdyenPaymentClosingDeposit, 'id' | 'shopId' | 'totalAmount' | 'totalFeeAmount' | 'totalNetAmount'>
        & { payoutJobSchedule: (
          { __typename?: 'payoutJobSchedule' }
          & Pick<Types.PayoutJobSchedule, 'id' | 'startingDate' | 'closingDate' | 'depositCycle'>
        ) }
      ) }
    )> }
  )> }
);

export type AdyenPaymentDepositHistoryGetShopsQueryVariables = Types.Exact<{
  shopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type AdyenPaymentDepositHistoryGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);


export const AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultDocument = gql`
    query AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResult($remittanceResultId: uuid!) {
  adyenPaymentRemittanceResult(where: {id: {_eq: $remittanceResultId}}) {
    id
    remittedAt
    remittanceStatus
    remittanceAmount
    depositId
    gmoBankAccountId
    adyenPaymentRemittanceClosingDeposits {
      adyenPaymentClosingDeposit {
        id
        shopId
        totalAmount
        totalFeeAmount
        totalNetAmount
        payoutJobSchedule {
          id
          startingDate
          closingDate
          depositCycle
        }
      }
    }
  }
}
    `;

/**
 * __useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery__
 *
 * To run a query within a React component, call `useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery({
 *   variables: {
 *      remittanceResultId: // value for 'remittanceResultId'
 *   },
 * });
 */
export function useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery(baseOptions: Apollo.QueryHookOptions<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables>(AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultDocument, options);
      }
export function useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables>(AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultDocument, options);
        }
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery>;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultLazyQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultLazyQuery>;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryResult = Apollo.QueryResult<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultQueryVariables>;
export const AdyenPaymentDepositHistoryGetShopsDocument = gql`
    query AdyenPaymentDepositHistoryGetShops($shopIds: [uuid!]!) {
  shop(where: {shopId: {_in: $shopIds}}) {
    shopId
    name
  }
}
    `;

/**
 * __useAdyenPaymentDepositHistoryGetShopsQuery__
 *
 * To run a query within a React component, call `useAdyenPaymentDepositHistoryGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenPaymentDepositHistoryGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenPaymentDepositHistoryGetShopsQuery({
 *   variables: {
 *      shopIds: // value for 'shopIds'
 *   },
 * });
 */
export function useAdyenPaymentDepositHistoryGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AdyenPaymentDepositHistoryGetShopsQuery, AdyenPaymentDepositHistoryGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenPaymentDepositHistoryGetShopsQuery, AdyenPaymentDepositHistoryGetShopsQueryVariables>(AdyenPaymentDepositHistoryGetShopsDocument, options);
      }
export function useAdyenPaymentDepositHistoryGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenPaymentDepositHistoryGetShopsQuery, AdyenPaymentDepositHistoryGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenPaymentDepositHistoryGetShopsQuery, AdyenPaymentDepositHistoryGetShopsQueryVariables>(AdyenPaymentDepositHistoryGetShopsDocument, options);
        }
export type AdyenPaymentDepositHistoryGetShopsQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetShopsQuery>;
export type AdyenPaymentDepositHistoryGetShopsLazyQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetShopsLazyQuery>;
export type AdyenPaymentDepositHistoryGetShopsQueryResult = Apollo.QueryResult<AdyenPaymentDepositHistoryGetShopsQuery, AdyenPaymentDepositHistoryGetShopsQueryVariables>;