import React, { memo } from "react";
import styled from "styled-components";
import { Button } from "antd";

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
`;

const ClosedButton = styled(Button)`
  padding: 0;
`;

type Props = {
  handleClose: () => void;
};
export const NotificationListModalFooter = memo<Props>(({ handleClose }) => (
  <Footer>
    <ClosedButton type="text" onClick={handleClose}>
      閉じる
    </ClosedButton>
  </Footer>
));
