import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Tag } from "antd";
import Overflow from "rc-overflow";
import { isNotUndefined } from "util/type/primitive";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";

import {
  isNotificationTargetCompanyKey,
  isNotificationTargetShopKey,
  NotificationTargetKey,
} from "../../useNotifications";

type ItemType = {
  id?: string;
  name?: string;
};

const OverflowWrapper = styled(Overflow<ItemType>)`
  display: flex;
  flex-wrap: wrap;
  line-height: 22px;
  max-height: 54px;
  max-width: 100%;
  position: relative;
`;

const TargetTag = styled(Tag)`
  max-height: 54px;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  margin-inline-end: 2px;
`;

type Props = {
  targets: NotificationTargetKey[];
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
};

export const Targets = memo<Props>(
  ({ targets, accountAccessibleCompanies, accountAccessibleShops }) => {
    const data = useMemo(() => {
      if (targets.includes("broadcast")) return [{ name: "全体配信", id: "broadcast" }];
      const companyIdsMap = new Map(
        targets
          .filter((target) => isNotificationTargetCompanyKey(target))
          .map((key) => key.split(":")[1])
          .filter(isNotUndefined)
          .map((value) => [value, value]),
      );
      const shopIdsMap = new Map(
        targets
          .filter((target) => isNotificationTargetShopKey(target))
          .map((key) => key.split(":")[1])
          .filter(isNotUndefined)
          .map((value) => [value, value]),
      );
      const companyLabels =
        accountAccessibleCompanies
          ?.filter(({ company }) => company && companyIdsMap.get(company.id))
          ?.map(({ company }) => ({
            name: company?.name,
            id: company?.id,
          })) || [];
      const shopLabels =
        accountAccessibleShops
          ?.filter(({ shop }) => shopIdsMap.get(shop.shopId))
          .map(({ shop }) => ({ name: shop.name, id: shop.shopId })) || [];
      return [...companyLabels, ...shopLabels];
    }, [accountAccessibleCompanies, accountAccessibleShops, targets]);

    return (
      <OverflowWrapper
        data={data}
        maxCount="responsive"
        renderItem={(item) => <TargetTag>{item.name}</TargetTag>}
        renderRest={(items) => <TargetTag>+{items.length}...</TargetTag>}
      />
    );
  },
);
