import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";

import { message } from "components/antd/message";
import { grey } from "constants/colors";

import { AccessibleCompanies, AccessibleShops } from "../../types";

import { DetailModal } from "./DetailModal";
import { ImportantDetailModal } from "./ImportantDetailModal";
import { NotificationListModal } from "./NotificationListModal";
import { NotificationReadStatus, useNotificationReadStatus } from "./useNotificationReadStatus";
import { Notification, NotificationCategory, useNotifications } from "./useNotifications";

const BellIcon = styled(BellOutlined)`
  height: 24px;
  font-size: 24px;
  color: white;
  transition: 0.3s;
`;

const BellIconContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
  &:hover span {
    color: ${grey[5]};
  }
  transition: 0.3s;
`;

export type TransformNotification = ReturnType<typeof transformNotificationData>;
export type DisplayNotificationCategory = NotificationCategory | "all";
export const transformNotificationData = (
  notification: Notification,
  readStatusNotificationsMap: Map<string, NotificationReadStatus>,
  browserId: string,
) => {
  const notificationReadStatus = readStatusNotificationsMap.get(notification.id);
  const readTimestamp = notificationReadStatus?.readStatus?.find(
    (status) => status.browserId === browserId,
  )?.readAt;

  return {
    ...notification,
    availableFrom: dayjs(notification.availableFrom.toDate()).format("YYYY/MM/DD HH:mm"),
    isImportant: notification.labels.includes("important"),
    readStatus: notificationReadStatus,
    isUnread: readTimestamp ? notification.updatedAt > readTimestamp : true,
  };
};

type Props = {
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  accountId?: string;
};

export const NotificationContainer = memo<Props>(
  ({ accountAccessibleCompanies, accountAccessibleShops, accountId }) => {
    const [isNotificationListVisible, setIsNotificationListVisible] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<TransformNotification | null>(
      null,
    );
    const [category, setCategory] = useState<DisplayNotificationCategory>("all");
    const [isUnread, setIsUnread] = useState(false);

    const { notifications, error: notificationError } = useNotifications({
      accountAccessibleCompanies,
      accountAccessibleShops,
    });

    const {
      readStatusList,
      readStatusNotificationsMap,
      browserId,
      updateNotificationReadStatus,
      error: readStatusNotificationsError,
    } = useNotificationReadStatus({
      userId: accountId,
    });

    const transformedNotifications = useMemo(
      () =>
        notifications.map((notification) =>
          transformNotificationData(notification, readStatusNotificationsMap, browserId),
        ),
      [browserId, notifications, readStatusNotificationsMap],
    );
    const filteredNotifications = useMemo(
      () =>
        transformedNotifications.filter(
          (notification) =>
            (!isUnread || notification.isUnread === isUnread) &&
            (category === "all" || notification.category === category),
        ),
      [category, isUnread, transformedNotifications],
    );
    const importantUnreadNotifications = useMemo(
      () =>
        readStatusList
          ? transformedNotifications.filter(
              (notification) => notification.isImportant && notification.isUnread,
            )
          : [],
      [readStatusList, transformedNotifications],
    );

    const unreadCount = useMemo(
      () =>
        readStatusList
          ? transformedNotifications.filter((notification) => notification.isUnread).length
          : 0,
      [readStatusList, transformedNotifications],
    );

    const handleNotificationRead = useCallback(
      async (readNotificationId: string, expiredAt: Timestamp) => {
        const result = await updateNotificationReadStatus({ readNotificationId, expiredAt });
        if (result.error) {
          message.error("既読情報の更新に失敗しました。");
        }
      },
      [updateNotificationReadStatus],
    );

    const onChangeCategory = useCallback((category: DisplayNotificationCategory) => {
      setCategory(category);
    }, []);

    const onChangeUnreadStatus = useCallback(() => {
      setIsUnread((prev) => !prev);
    }, []);

    const handleOpenDetailModal = useCallback(
      async (notificationId: string) => {
        const notification = transformedNotifications.find(
          (notification) => notification.id === notificationId,
        );
        if (!notification) return;
        setSelectedNotification(notification);
        if (notification.isUnread) {
          await handleNotificationRead(notification.id, notification.availableUntil);
        }
      },
      [handleNotificationRead, transformedNotifications],
    );

    useEffect(() => {
      if (notificationError || readStatusNotificationsError) {
        message.error("通信に失敗しました");
      }
    }, [notificationError, readStatusNotificationsError]);

    return (
      <>
        {importantUnreadNotifications[0] && (
          <ImportantDetailModal
            notification={importantUnreadNotifications[0]}
            onClose={handleNotificationRead}
            accountAccessibleCompanies={accountAccessibleCompanies}
            accountAccessibleShops={accountAccessibleShops}
          />
        )}
        {selectedNotification && (
          <DetailModal
            notification={selectedNotification}
            onClose={() => setSelectedNotification(null)}
            accountAccessibleCompanies={accountAccessibleCompanies}
            accountAccessibleShops={accountAccessibleShops}
          />
        )}
        <BellIconContainer role="button" onClick={() => setIsNotificationListVisible(true)}>
          <Badge count={unreadCount} overflowCount={99}>
            <BellIcon />
          </Badge>
        </BellIconContainer>
        <NotificationListModal
          onClose={() => setIsNotificationListVisible(false)}
          notifications={filteredNotifications}
          open={isNotificationListVisible}
          isUnread={isUnread}
          onChangeCategory={onChangeCategory}
          onChangeUnreadStatus={onChangeUnreadStatus}
          accountAccessibleCompanies={accountAccessibleCompanies}
          accountAccessibleShops={accountAccessibleShops}
          openDetail={handleOpenDetailModal}
        />
      </>
    );
  },
);
