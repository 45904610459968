import { useEffect } from "react";

import { isBulkEditConditionValueChanged } from "./functions";
import type {
  BulkEditConditions,
  BulkEditConditionsEntries,
  Entries,
  RowItemChoice,
} from "./types";

const includeKeys = ["isVisibleForCustomer", "isVisibleForStaff", "isSoldOut"] as const;
type IncludeKeys = (typeof includeKeys)[number];

const filterCondition = (
  entry: BulkEditConditionsEntries[number],
): entry is Entries<Required<Pick<BulkEditConditions, IncludeKeys>>>[number] => {
  const [key, value] = entry;

  return includeKeys.some((v) => v === key) && isBulkEditConditionValueChanged(value);
};

export const useApplyBulkEditConditionsToChoices = ({
  choices,
  bulkEditConditions,
  currentPageSelectedChoiceRowKeys,
  setDisplayedChoices,
}: {
  choices: RowItemChoice[];
  bulkEditConditions: BulkEditConditions;
  currentPageSelectedChoiceRowKeys: string[];
  setDisplayedChoices: React.Dispatch<React.SetStateAction<RowItemChoice[]>>;
}) => {
  useEffect(() => {
    const choiceAppliedConditions = Object.fromEntries(
      (Object.entries(bulkEditConditions) as BulkEditConditionsEntries)
        .filter(filterCondition)
        .map(([key, value]) => {
          if (key === "isSoldOut") {
            return ["stock", { currentStockNum: value ? 0 : null }];
          }

          return [key, value];
        }),
    );

    const nextChoices = choices.map<RowItemChoice>((choice) => {
      if (!currentPageSelectedChoiceRowKeys.includes(`${choice.menuId}-${choice.choiceId}`)) {
        return choice;
      }

      return {
        ...choice,
        shopChoices: [{ ...choice.shopChoices[0], ...choiceAppliedConditions }],
      };
    });

    setDisplayedChoices(nextChoices);
  }, [choices, bulkEditConditions, currentPageSelectedChoiceRowKeys, setDisplayedChoices]);
};
