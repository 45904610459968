import React, { memo, useMemo } from "react";
import { Select } from "antd";

import { FormItemProps } from "components/antd/Form";
import { Corporation } from "types/graphql";

import { EditGmoBankAccountFormItem } from "../useEditGmoBankAccountForm";

type Props = {
  corporations: Pick<Corporation, "id" | "name">[];
} & Omit<FormItemProps, "children" | "name">;

export const CorporationField = memo<Props>(({ corporations, ...props }) => {
  const options = useMemo(
    () =>
      corporations.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [corporations],
  );

  return (
    <EditGmoBankAccountFormItem.NonProperty noStyle>
      <EditGmoBankAccountFormItem label="法人" name="corporationId" rules={[{ required: true }]}>
        <Select
          showSearch
          allowClear
          placeholder="選択してください"
          optionFilterProp="label"
          options={options}
        />
      </EditGmoBankAccountFormItem>
    </EditGmoBankAccountFormItem.NonProperty>
  );
});
