import { DashboardFeatureEnum } from "types/graphql";

export const dashboardFeatures: Record<DashboardFeatureEnum, string> = {
  [DashboardFeatureEnum.Questionnaire]: "アンケート",
  [DashboardFeatureEnum.Aggregation]: "集計",
  [DashboardFeatureEnum.BusinessIntelligenceIntegration]: "BI 連携",
  [DashboardFeatureEnum.SalesBudget]: "目標管理",
  [DashboardFeatureEnum.DailyReport]: "日次処理",
  [DashboardFeatureEnum.AccountingHistory]: "会計履歴",
  [DashboardFeatureEnum.TableClearHistory]: "テーブルクリア履歴",
  [DashboardFeatureEnum.PaymentMethodSettings]: "支払方法等設定",
  [DashboardFeatureEnum.MenuMasterData]: "メニューマスター",
  [DashboardFeatureEnum.CompanyManagement]: "業態管理",
  [DashboardFeatureEnum.ShopManagement]: "店舗管理",
  [DashboardFeatureEnum.CustomerRelationshipManagement]: "CRM",
  [DashboardFeatureEnum.DashboardAccountManagement]: "アカウント管理",
  [DashboardFeatureEnum.TerminalPayment]: "ダイニーキャッシュレス",
};

export const isDashboardFeatureKey = (key: string): key is DashboardFeatureEnum =>
  key in dashboardFeatures;

export const getDashboardFeatureName = (key: string) =>
  isDashboardFeatureKey(key) ? dashboardFeatures[key] : ("" as const);
