// ヘルプセンター TOP
export const helpPageUrl = "https://dinii.wraptas.site/";

// ヘルプセンター 「POS分析データの定義と仕様」 ページ
export const posAnalyticsHelpPageUrl =
  "https://dinii.wraptas.site/f23ed4fafc4d44219942564b88b8f291";

// MO ウェルカム画面の画像設定の「モバイルオーダーのチェックイン画面の背景画像のサンプル一覧」
export const mobileOrderWelcomeScreenBackgroundImageSamplePage =
  "https://dinii.wraptas.site/12971045ad748097841dd451e18e869b";
