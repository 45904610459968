import React, { memo } from "react";
import styled from "styled-components";

import { viewport } from "constants/viewport";

const ICON_DISPLAY_MAX_RANK = 3;

const RankText = styled.span`
  font-weight: 500;
`;

const StyledImg = styled.img`
  width: 36px;
  @media ${viewport.smartphone}, ${viewport.tablet} {
    width: 24px;
  }
`;

type Props = {
  rank: number;
};

export const RankCell = memo<Props>(({ rank }) => {
  if (rank > ICON_DISPLAY_MAX_RANK) return <RankText>{rank}</RankText>;

  return (
    <StyledImg src={`/questionnaire-analytics/shop_score_rank_${rank}.png`} alt={`rank ${rank}`} />
  );
});
