import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditGmoBankAccountGetShopsByCompany = gql`
    query EditGmoBankAccountGetShopsByCompany {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;
export const EditGmoBankAccountGetCorporation = gql`
    query EditGmoBankAccountGetCorporation {
  corporation(order_by: {name: asc}) {
    id
    name
  }
}
    `;
export const EditGmoBankAccountGetGmoBankAccountDetail = gql`
    query EditGmoBankAccountGetGmoBankAccountDetail($input: DashboardAccountGmoBankAccountDetailInput!) {
  onlinePaymentRoot {
    gmoBankAccountDetail(input: $input) {
      gmoBankAccountId
      bankCode
      branchCode
      accountType
      accountNumber
      accountName
    }
  }
}
    `;
export const EditGmoBankAccountGetGmoBankAccount = gql`
    query EditGmoBankAccountGetGmoBankAccount($gmoBankAccountId: uuid!) {
  gmoBankAccount(where: {id: {_eq: $gmoBankAccountId}}) {
    id
    name
    corporationId
    gmoBankAccountShops {
      shopId
      depositCycleType
    }
  }
}
    `;
export const EditGmoBankAccountUpdateAccount = gql`
    mutation EditGmoBankAccountUpdateAccount($input: DashboardAccountCreateOrUpdateGmoBankAccountInput!) {
  onlinePaymentRoot {
    createOrUpdateGmoBankAccount(input: $input) {
      gmoBankAccountId
    }
  }
}
    `;
export type EditGmoBankAccountGetShopsByCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditGmoBankAccountGetShopsByCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'name'>
    & { shops: Array<(
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'shopId' | 'name'>
    )> }
  )> }
);

export type EditGmoBankAccountGetCorporationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EditGmoBankAccountGetCorporationQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
  )> }
);

export type EditGmoBankAccountGetGmoBankAccountDetailQueryVariables = Types.Exact<{
  input: Types.DashboardAccountGmoBankAccountDetailInput;
}>;


export type EditGmoBankAccountGetGmoBankAccountDetailQuery = (
  { __typename?: 'query_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootQuery' }
    & { gmoBankAccountDetail: (
      { __typename?: 'DashboardAccountGmoBankAccountDetailResult' }
      & Pick<Types.DashboardAccountGmoBankAccountDetailResult, 'gmoBankAccountId' | 'bankCode' | 'branchCode' | 'accountType' | 'accountNumber' | 'accountName'>
    ) }
  )> }
);

export type EditGmoBankAccountGetGmoBankAccountQueryVariables = Types.Exact<{
  gmoBankAccountId: Types.Scalars['uuid'];
}>;


export type EditGmoBankAccountGetGmoBankAccountQuery = (
  { __typename?: 'query_root' }
  & { gmoBankAccount: Array<(
    { __typename?: 'gmoBankAccount' }
    & Pick<Types.GmoBankAccount, 'id' | 'name' | 'corporationId'>
    & { gmoBankAccountShops: Array<(
      { __typename?: 'gmoBankAccountShop' }
      & Pick<Types.GmoBankAccountShop, 'shopId' | 'depositCycleType'>
    )> }
  )> }
);

export type EditGmoBankAccountUpdateAccountMutationVariables = Types.Exact<{
  input: Types.DashboardAccountCreateOrUpdateGmoBankAccountInput;
}>;


export type EditGmoBankAccountUpdateAccountMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { createOrUpdateGmoBankAccount: (
      { __typename?: 'DashboardAccountCreateOrUpdateGmoBankAccountResult' }
      & Pick<Types.DashboardAccountCreateOrUpdateGmoBankAccountResult, 'gmoBankAccountId'>
    ) }
  )> }
);


export const EditGmoBankAccountGetShopsByCompanyDocument = gql`
    query EditGmoBankAccountGetShopsByCompany {
  company(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
    name
    shops(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
      shopId
      name
    }
  }
}
    `;

/**
 * __useEditGmoBankAccountGetShopsByCompanyQuery__
 *
 * To run a query within a React component, call `useEditGmoBankAccountGetShopsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGmoBankAccountGetShopsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGmoBankAccountGetShopsByCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditGmoBankAccountGetShopsByCompanyQuery(baseOptions?: Apollo.QueryHookOptions<EditGmoBankAccountGetShopsByCompanyQuery, EditGmoBankAccountGetShopsByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGmoBankAccountGetShopsByCompanyQuery, EditGmoBankAccountGetShopsByCompanyQueryVariables>(EditGmoBankAccountGetShopsByCompanyDocument, options);
      }
export function useEditGmoBankAccountGetShopsByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGmoBankAccountGetShopsByCompanyQuery, EditGmoBankAccountGetShopsByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGmoBankAccountGetShopsByCompanyQuery, EditGmoBankAccountGetShopsByCompanyQueryVariables>(EditGmoBankAccountGetShopsByCompanyDocument, options);
        }
export type EditGmoBankAccountGetShopsByCompanyQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetShopsByCompanyQuery>;
export type EditGmoBankAccountGetShopsByCompanyLazyQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetShopsByCompanyLazyQuery>;
export type EditGmoBankAccountGetShopsByCompanyQueryResult = Apollo.QueryResult<EditGmoBankAccountGetShopsByCompanyQuery, EditGmoBankAccountGetShopsByCompanyQueryVariables>;
export const EditGmoBankAccountGetCorporationDocument = gql`
    query EditGmoBankAccountGetCorporation {
  corporation(order_by: {name: asc}) {
    id
    name
  }
}
    `;

/**
 * __useEditGmoBankAccountGetCorporationQuery__
 *
 * To run a query within a React component, call `useEditGmoBankAccountGetCorporationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGmoBankAccountGetCorporationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGmoBankAccountGetCorporationQuery({
 *   variables: {
 *   },
 * });
 */
export function useEditGmoBankAccountGetCorporationQuery(baseOptions?: Apollo.QueryHookOptions<EditGmoBankAccountGetCorporationQuery, EditGmoBankAccountGetCorporationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGmoBankAccountGetCorporationQuery, EditGmoBankAccountGetCorporationQueryVariables>(EditGmoBankAccountGetCorporationDocument, options);
      }
export function useEditGmoBankAccountGetCorporationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGmoBankAccountGetCorporationQuery, EditGmoBankAccountGetCorporationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGmoBankAccountGetCorporationQuery, EditGmoBankAccountGetCorporationQueryVariables>(EditGmoBankAccountGetCorporationDocument, options);
        }
export type EditGmoBankAccountGetCorporationQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetCorporationQuery>;
export type EditGmoBankAccountGetCorporationLazyQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetCorporationLazyQuery>;
export type EditGmoBankAccountGetCorporationQueryResult = Apollo.QueryResult<EditGmoBankAccountGetCorporationQuery, EditGmoBankAccountGetCorporationQueryVariables>;
export const EditGmoBankAccountGetGmoBankAccountDetailDocument = gql`
    query EditGmoBankAccountGetGmoBankAccountDetail($input: DashboardAccountGmoBankAccountDetailInput!) {
  onlinePaymentRoot {
    gmoBankAccountDetail(input: $input) {
      gmoBankAccountId
      bankCode
      branchCode
      accountType
      accountNumber
      accountName
    }
  }
}
    `;

/**
 * __useEditGmoBankAccountGetGmoBankAccountDetailQuery__
 *
 * To run a query within a React component, call `useEditGmoBankAccountGetGmoBankAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGmoBankAccountGetGmoBankAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGmoBankAccountGetGmoBankAccountDetailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGmoBankAccountGetGmoBankAccountDetailQuery(baseOptions: Apollo.QueryHookOptions<EditGmoBankAccountGetGmoBankAccountDetailQuery, EditGmoBankAccountGetGmoBankAccountDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGmoBankAccountGetGmoBankAccountDetailQuery, EditGmoBankAccountGetGmoBankAccountDetailQueryVariables>(EditGmoBankAccountGetGmoBankAccountDetailDocument, options);
      }
export function useEditGmoBankAccountGetGmoBankAccountDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGmoBankAccountGetGmoBankAccountDetailQuery, EditGmoBankAccountGetGmoBankAccountDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGmoBankAccountGetGmoBankAccountDetailQuery, EditGmoBankAccountGetGmoBankAccountDetailQueryVariables>(EditGmoBankAccountGetGmoBankAccountDetailDocument, options);
        }
export type EditGmoBankAccountGetGmoBankAccountDetailQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetGmoBankAccountDetailQuery>;
export type EditGmoBankAccountGetGmoBankAccountDetailLazyQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetGmoBankAccountDetailLazyQuery>;
export type EditGmoBankAccountGetGmoBankAccountDetailQueryResult = Apollo.QueryResult<EditGmoBankAccountGetGmoBankAccountDetailQuery, EditGmoBankAccountGetGmoBankAccountDetailQueryVariables>;
export const EditGmoBankAccountGetGmoBankAccountDocument = gql`
    query EditGmoBankAccountGetGmoBankAccount($gmoBankAccountId: uuid!) {
  gmoBankAccount(where: {id: {_eq: $gmoBankAccountId}}) {
    id
    name
    corporationId
    gmoBankAccountShops {
      shopId
      depositCycleType
    }
  }
}
    `;

/**
 * __useEditGmoBankAccountGetGmoBankAccountQuery__
 *
 * To run a query within a React component, call `useEditGmoBankAccountGetGmoBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGmoBankAccountGetGmoBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGmoBankAccountGetGmoBankAccountQuery({
 *   variables: {
 *      gmoBankAccountId: // value for 'gmoBankAccountId'
 *   },
 * });
 */
export function useEditGmoBankAccountGetGmoBankAccountQuery(baseOptions: Apollo.QueryHookOptions<EditGmoBankAccountGetGmoBankAccountQuery, EditGmoBankAccountGetGmoBankAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGmoBankAccountGetGmoBankAccountQuery, EditGmoBankAccountGetGmoBankAccountQueryVariables>(EditGmoBankAccountGetGmoBankAccountDocument, options);
      }
export function useEditGmoBankAccountGetGmoBankAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGmoBankAccountGetGmoBankAccountQuery, EditGmoBankAccountGetGmoBankAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGmoBankAccountGetGmoBankAccountQuery, EditGmoBankAccountGetGmoBankAccountQueryVariables>(EditGmoBankAccountGetGmoBankAccountDocument, options);
        }
export type EditGmoBankAccountGetGmoBankAccountQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetGmoBankAccountQuery>;
export type EditGmoBankAccountGetGmoBankAccountLazyQueryHookResult = ReturnType<typeof useEditGmoBankAccountGetGmoBankAccountLazyQuery>;
export type EditGmoBankAccountGetGmoBankAccountQueryResult = Apollo.QueryResult<EditGmoBankAccountGetGmoBankAccountQuery, EditGmoBankAccountGetGmoBankAccountQueryVariables>;
export const EditGmoBankAccountUpdateAccountDocument = gql`
    mutation EditGmoBankAccountUpdateAccount($input: DashboardAccountCreateOrUpdateGmoBankAccountInput!) {
  onlinePaymentRoot {
    createOrUpdateGmoBankAccount(input: $input) {
      gmoBankAccountId
    }
  }
}
    `;
export type EditGmoBankAccountUpdateAccountMutationFn = Apollo.MutationFunction<EditGmoBankAccountUpdateAccountMutation, EditGmoBankAccountUpdateAccountMutationVariables>;

/**
 * __useEditGmoBankAccountUpdateAccountMutation__
 *
 * To run a mutation, you first call `useEditGmoBankAccountUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGmoBankAccountUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGmoBankAccountUpdateAccountMutation, { data, loading, error }] = useEditGmoBankAccountUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGmoBankAccountUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<EditGmoBankAccountUpdateAccountMutation, EditGmoBankAccountUpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGmoBankAccountUpdateAccountMutation, EditGmoBankAccountUpdateAccountMutationVariables>(EditGmoBankAccountUpdateAccountDocument, options);
      }
export type EditGmoBankAccountUpdateAccountMutationHookResult = ReturnType<typeof useEditGmoBankAccountUpdateAccountMutation>;
export type EditGmoBankAccountUpdateAccountMutationResult = Apollo.MutationResult<EditGmoBankAccountUpdateAccountMutation>;
export type EditGmoBankAccountUpdateAccountMutationOptions = Apollo.BaseMutationOptions<EditGmoBankAccountUpdateAccountMutation, EditGmoBankAccountUpdateAccountMutationVariables>;