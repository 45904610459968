import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationId = gql`
    query AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationId($corporationId: uuid!, $remittedAtFrom: timestamptz!, $remittedAtTo: timestamptz!) {
  adyenPaymentRemittanceResult(
    where: {gmoBankAccount: {corporationId: {_eq: $corporationId}}, remittedAt: {_gte: $remittedAtFrom, _lt: $remittedAtTo}}
  ) {
    id
    remittedAt
    remittanceStatus
    remittanceAmount
    depositId
    gmoBankAccountId
    adyenPaymentRemittanceClosingDeposits {
      adyenPaymentClosingDeposit {
        id
        shopId
        totalAmount
        totalFeeAmount
        totalNetAmount
        payoutJobSchedule {
          id
          startingDate
          closingDate
          depositCycle
        }
      }
    }
  }
}
    `;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables = Types.Exact<{
  corporationId: Types.Scalars['uuid'];
  remittedAtFrom: Types.Scalars['timestamptz'];
  remittedAtTo: Types.Scalars['timestamptz'];
}>;


export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery = (
  { __typename?: 'query_root' }
  & { adyenPaymentRemittanceResult: Array<(
    { __typename?: 'adyenPaymentRemittanceResult' }
    & Pick<Types.AdyenPaymentRemittanceResult, 'id' | 'remittedAt' | 'remittanceStatus' | 'remittanceAmount' | 'depositId' | 'gmoBankAccountId'>
    & { adyenPaymentRemittanceClosingDeposits: Array<(
      { __typename?: 'adyenPaymentRemittanceClosingDeposit' }
      & { adyenPaymentClosingDeposit: (
        { __typename?: 'adyenPaymentClosingDeposit' }
        & Pick<Types.AdyenPaymentClosingDeposit, 'id' | 'shopId' | 'totalAmount' | 'totalFeeAmount' | 'totalNetAmount'>
        & { payoutJobSchedule: (
          { __typename?: 'payoutJobSchedule' }
          & Pick<Types.PayoutJobSchedule, 'id' | 'startingDate' | 'closingDate' | 'depositCycle'>
        ) }
      ) }
    )> }
  )> }
);


export const AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdDocument = gql`
    query AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationId($corporationId: uuid!, $remittedAtFrom: timestamptz!, $remittedAtTo: timestamptz!) {
  adyenPaymentRemittanceResult(
    where: {gmoBankAccount: {corporationId: {_eq: $corporationId}}, remittedAt: {_gte: $remittedAtFrom, _lt: $remittedAtTo}}
  ) {
    id
    remittedAt
    remittanceStatus
    remittanceAmount
    depositId
    gmoBankAccountId
    adyenPaymentRemittanceClosingDeposits {
      adyenPaymentClosingDeposit {
        id
        shopId
        totalAmount
        totalFeeAmount
        totalNetAmount
        payoutJobSchedule {
          id
          startingDate
          closingDate
          depositCycle
        }
      }
    }
  }
}
    `;

/**
 * __useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery__
 *
 * To run a query within a React component, call `useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery({
 *   variables: {
 *      corporationId: // value for 'corporationId'
 *      remittedAtFrom: // value for 'remittedAtFrom'
 *      remittedAtTo: // value for 'remittedAtTo'
 *   },
 * });
 */
export function useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery(baseOptions: Apollo.QueryHookOptions<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables>(AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdDocument, options);
      }
export function useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables>(AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdDocument, options);
        }
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery>;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdLazyQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdLazyQuery>;
export type AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryResult = Apollo.QueryResult<AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery, AdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQueryVariables>;