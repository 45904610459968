import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const QuestionnaireAnalyticsCommentGetCustomerQuestionnaire = gql`
    query QuestionnaireAnalyticsCommentGetCustomerQuestionnaire($customerQuestionnaireId: uuid!) {
  customerQuestionnaire_by_pk(id: $customerQuestionnaireId) {
    id
    checkInEvent {
      checkedInAt
      shopId
      shop {
        name
      }
    }
    questionnaireAnswer {
      visitedAt
      answeredAt
      questionnaire {
        questionnaireQuestions {
          priority
          questionId
        }
      }
      questionAnswers {
        question {
          id
          category
          name
          required
          type
        }
        scoreTypeQuestionAnswer {
          score
        }
        birthdayTypeQuestionAnswer {
          birthday
        }
        textTypeQuestionAnswer {
          text
        }
        multipleChoiceTypeQuestionAnswer {
          id
          multipleChoiceTypeQuestionChoiceAnswers {
            id
            questionChoice {
              name
            }
          }
        }
      }
    }
    customer {
      lineProfile {
        name
        profileImage
      }
      checkInEvents(where: {tableUser: {tableClearReason: {_is_null: true}}}) {
        shopId
        checkedInAt
      }
    }
  }
}
    `;
export type QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables = Types.Exact<{
  customerQuestionnaireId: Types.Scalars['uuid'];
}>;


export type QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery = (
  { __typename?: 'query_root' }
  & { customerQuestionnaire_by_pk?: Types.Maybe<(
    { __typename?: 'customerQuestionnaire' }
    & Pick<Types.CustomerQuestionnaire, 'id'>
    & { checkInEvent?: Types.Maybe<(
      { __typename?: 'checkInEvent' }
      & Pick<Types.CheckInEvent, 'checkedInAt' | 'shopId'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
      ) }
    )>, questionnaireAnswer?: Types.Maybe<(
      { __typename?: 'questionnaireAnswer' }
      & Pick<Types.QuestionnaireAnswer, 'visitedAt' | 'answeredAt'>
      & { questionnaire?: Types.Maybe<(
        { __typename?: 'questionnaire' }
        & { questionnaireQuestions: Array<(
          { __typename?: 'questionnaireQuestion' }
          & Pick<Types.QuestionnaireQuestion, 'priority' | 'questionId'>
        )> }
      )>, questionAnswers: Array<(
        { __typename?: 'questionAnswer' }
        & { question: (
          { __typename?: 'question' }
          & Pick<Types.Question, 'id' | 'category' | 'name' | 'required' | 'type'>
        ), scoreTypeQuestionAnswer?: Types.Maybe<(
          { __typename?: 'scoreTypeQuestionAnswer' }
          & Pick<Types.ScoreTypeQuestionAnswer, 'score'>
        )>, birthdayTypeQuestionAnswer?: Types.Maybe<(
          { __typename?: 'birthdayTypeQuestionAnswer' }
          & Pick<Types.BirthdayTypeQuestionAnswer, 'birthday'>
        )>, textTypeQuestionAnswer?: Types.Maybe<(
          { __typename?: 'textTypeQuestionAnswer' }
          & Pick<Types.TextTypeQuestionAnswer, 'text'>
        )>, multipleChoiceTypeQuestionAnswer?: Types.Maybe<(
          { __typename?: 'multipleChoiceTypeQuestionAnswer' }
          & Pick<Types.MultipleChoiceTypeQuestionAnswer, 'id'>
          & { multipleChoiceTypeQuestionChoiceAnswers: Array<(
            { __typename?: 'multipleChoiceTypeQuestionChoiceAnswer' }
            & Pick<Types.MultipleChoiceTypeQuestionChoiceAnswer, 'id'>
            & { questionChoice: (
              { __typename?: 'multipleChoiceTypeQuestionChoice' }
              & Pick<Types.MultipleChoiceTypeQuestionChoice, 'name'>
            ) }
          )> }
        )> }
      )> }
    )>, customer: (
      { __typename?: 'customer' }
      & { lineProfile?: Types.Maybe<(
        { __typename?: 'lineProfile' }
        & Pick<Types.LineProfile, 'name' | 'profileImage'>
      )>, checkInEvents: Array<(
        { __typename?: 'checkInEvent' }
        & Pick<Types.CheckInEvent, 'shopId' | 'checkedInAt'>
      )> }
    ) }
  )> }
);


export const QuestionnaireAnalyticsCommentGetCustomerQuestionnaireDocument = gql`
    query QuestionnaireAnalyticsCommentGetCustomerQuestionnaire($customerQuestionnaireId: uuid!) {
  customerQuestionnaire_by_pk(id: $customerQuestionnaireId) {
    id
    checkInEvent {
      checkedInAt
      shopId
      shop {
        name
      }
    }
    questionnaireAnswer {
      visitedAt
      answeredAt
      questionnaire {
        questionnaireQuestions {
          priority
          questionId
        }
      }
      questionAnswers {
        question {
          id
          category
          name
          required
          type
        }
        scoreTypeQuestionAnswer {
          score
        }
        birthdayTypeQuestionAnswer {
          birthday
        }
        textTypeQuestionAnswer {
          text
        }
        multipleChoiceTypeQuestionAnswer {
          id
          multipleChoiceTypeQuestionChoiceAnswers {
            id
            questionChoice {
              name
            }
          }
        }
      }
    }
    customer {
      lineProfile {
        name
        profileImage
      }
      checkInEvents(where: {tableUser: {tableClearReason: {_is_null: true}}}) {
        shopId
        checkedInAt
      }
    }
  }
}
    `;

/**
 * __useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery({
 *   variables: {
 *      customerQuestionnaireId: // value for 'customerQuestionnaireId'
 *   },
 * });
 */
export function useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery, QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery, QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables>(QuestionnaireAnalyticsCommentGetCustomerQuestionnaireDocument, options);
      }
export function useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery, QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery, QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables>(QuestionnaireAnalyticsCommentGetCustomerQuestionnaireDocument, options);
        }
export type QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery>;
export type QuestionnaireAnalyticsCommentGetCustomerQuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireAnalyticsCommentGetCustomerQuestionnaireLazyQuery>;
export type QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQuery, QuestionnaireAnalyticsCommentGetCustomerQuestionnaireQueryVariables>;