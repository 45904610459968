import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const IsAdyenTerminalPaymentFeatureAvailableGetShopIds = gql`
    query IsAdyenTerminalPaymentFeatureAvailableGetShopIds($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
  }
}
    `;
export const IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfig = gql`
    query IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfig($shopIds: [uuid!]!) {
  adyenTerminalPaymentShopConfig(
    where: {shopId: {_in: $shopIds}, archivedAt: {_is_null: true}}
  ) {
    shopId
  }
}
    `;
export type IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
  )> }
);

export type IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables = Types.Exact<{
  shopIds: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery = (
  { __typename?: 'query_root' }
  & { adyenTerminalPaymentShopConfig: Array<(
    { __typename?: 'adyenTerminalPaymentShopConfig' }
    & Pick<Types.AdyenTerminalPaymentShopConfig, 'shopId'>
  )> }
);


export const IsAdyenTerminalPaymentFeatureAvailableGetShopIdsDocument = gql`
    query IsAdyenTerminalPaymentFeatureAvailableGetShopIds($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
  }
}
    `;

/**
 * __useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery__
 *
 * To run a query within a React component, call `useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery(baseOptions: Apollo.QueryHookOptions<IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery, IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery, IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables>(IsAdyenTerminalPaymentFeatureAvailableGetShopIdsDocument, options);
      }
export function useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery, IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery, IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables>(IsAdyenTerminalPaymentFeatureAvailableGetShopIdsDocument, options);
        }
export type IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryHookResult = ReturnType<typeof useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery>;
export type IsAdyenTerminalPaymentFeatureAvailableGetShopIdsLazyQueryHookResult = ReturnType<typeof useIsAdyenTerminalPaymentFeatureAvailableGetShopIdsLazyQuery>;
export type IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryResult = Apollo.QueryResult<IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQuery, IsAdyenTerminalPaymentFeatureAvailableGetShopIdsQueryVariables>;
export const IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigDocument = gql`
    query IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfig($shopIds: [uuid!]!) {
  adyenTerminalPaymentShopConfig(
    where: {shopId: {_in: $shopIds}, archivedAt: {_is_null: true}}
  ) {
    shopId
  }
}
    `;

/**
 * __useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery__
 *
 * To run a query within a React component, call `useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery({
 *   variables: {
 *      shopIds: // value for 'shopIds'
 *   },
 * });
 */
export function useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery(baseOptions: Apollo.QueryHookOptions<IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery, IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery, IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables>(IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigDocument, options);
      }
export function useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery, IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery, IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables>(IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigDocument, options);
        }
export type IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryHookResult = ReturnType<typeof useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery>;
export type IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigLazyQueryHookResult = ReturnType<typeof useIsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigLazyQuery>;
export type IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryResult = Apollo.QueryResult<IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQuery, IsAdyenTerminalPaymentFeatureAvailableGetAdyenTerminalPaymentShopConfigQueryVariables>;