import React, { memo } from "react";
import { FormListFieldData } from "antd/lib";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditOptionWinboardOptionFormItem } from "../useEditOptionWinboardOptionForm";

type Props = {
  loading?: boolean;
};

type FormListFieldDataSource = {
  name: FormListFieldData["name"];
  key: FormListFieldData["key"];
};

export const WinboardOptionChoiceTable = memo<Props>(({ loading }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "name",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ option }) => [
              option?.choices.map((choice) => choice.name),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue(["option", "choices", field.name, "name"]);

              return <span>{choiceName}</span>;
            }}
          </EditOptionWinboardOptionFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "code",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "code"]}
            rules={[
              { required: true, pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" },
            ]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "name"]}
            rules={[{ required: true, max: 30, message: "30文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "bumonCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "bumonCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "bumonName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "bumonName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "categoryCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "categoryCode"]}
            rules={[{ pattern: /^\d{1,8}$/, message: "8桁以内の数字で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "categoryName",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditOptionWinboardOptionFormItem
            name={[field.name, "winboardMenus", 0, "categoryName"]}
            rules={[{ max: 15, message: "15文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditOptionWinboardOptionFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={["option", "choices"]}>
      {(optionChoiceFields) => (
        <Table
          rowKey="key"
          columns={columns}
          dataSource={optionChoiceFields}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
