import React, { memo } from "react";
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";

import { ExternalLink } from "components/ExternalLink";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { mobileOrderWelcomeScreenBackgroundImageSamplePage } from "constants/externalLinks";

import { MobileOrderDesignSettingsFormItem } from "..";

const HelpText = styled.div`
  color: gray;
`;

const UploadImageHelpText = styled.div`
  color: black;
  font-size: 14px;
`;

export const MobileOrderWelcomeScreenSettings = memo(() => (
  <>
    <span>背景の設定</span>
    <Spacer size={12} />

    <MobileOrderDesignSettingsFormItem name="backgroundImgSrc">
      <MobileOrderDesignSettingsFormItem.NonProperty noStyle shouldUpdate>
        {({ getFieldValue, setFields }) => {
          const imageNamePath = ["backgroundImgSrc"];
          const imageUrl: string = getFieldValue(imageNamePath);
          return (
            <ImageField
              image={imageUrl}
              setUploadImage={(image) => setFields([{ name: imageNamePath, value: image }])}
              uploadImageApiKey="mobileOrderWelcomeScreenSettings"
              formName="mobileOrderWelcomeScreenSettingsForm"
              isMovieAccepted
              height={395}
              width={195}
              bottomHelpText={
                <>
                  最大アップロードサイズ：10MB
                  <br />
                  <UploadImageHelpText>
                    アップロード画像のご用意が難しい場合
                    <ExternalLink
                      externalLinkUrl={mobileOrderWelcomeScreenBackgroundImageSamplePage}
                      text="こちらのページより"
                    />
                    任意の画像をダウンロードし、ご利用ください
                  </UploadImageHelpText>
                </>
              }
            />
          );
        }}
      </MobileOrderDesignSettingsFormItem.NonProperty>
    </MobileOrderDesignSettingsFormItem>

    <label htmlFor="welcomeMessage">メッセージの表示</label>
    <HelpText>設定したメッセージは業態ごとに表示されます</HelpText>
    <Spacer size={12} />
    <MobileOrderDesignSettingsFormItem name="welcomeMessage">
      <TextArea rows={2} maxLength={90} showCount placeholder="メッセージ" />
    </MobileOrderDesignSettingsFormItem>
  </>
));
