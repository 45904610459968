import { useCallback, useEffect, useMemo, useState } from "react";
import { doc, FirestoreError, onSnapshot, setDoc, Timestamp } from "firebase/firestore";
import * as uuid from "uuid";

import { useAnalytics } from "hooks/useAnalytics";
import { firestore } from "libs/firebase";

export type NotificationReadStatus = {
  notificationId: string;
  expiredAt: Timestamp;
  readStatus: ReadStatus[];
};

export type UserNotificationReadStatuses = {
  userId: string;
  notifications: NotificationReadStatus[];
};

type ReadStatus = {
  readAt: Timestamp;
  browserId: string;
};
type Result<Data> =
  | {
      data: Data;
      error?: undefined;
    }
  | {
      data?: undefined;
      error: Error;
    };

const browserIdKey = "browserId";

export const useNotificationReadStatus = ({ userId }: { userId?: string }) => {
  const { logEvent } = useAnalytics();
  const [readStatusList, setReadStatusList] = useState<NotificationReadStatus[] | null>(null);
  const [error, setError] = useState<FirestoreError | null>(null);

  const [browserId, setBrowserId] = useState<string>("");

  useEffect(() => {
    const maybeBrowserId = localStorage.getItem(browserIdKey);
    const browserId = maybeBrowserId ? maybeBrowserId : uuid.v4();
    if (!maybeBrowserId) {
      localStorage.setItem(browserIdKey, browserId);
    }

    setBrowserId(browserId);
  }, []);

  useEffect(() => {
    if (!userId) return;

    const usersDocRef = doc(firestore, "clients", userId);
    const unsubscribe = onSnapshot(
      usersDocRef,
      (snapshot) => {
        snapshot.exists()
          ? setReadStatusList((snapshot.data() as UserNotificationReadStatuses).notifications)
          : setReadStatusList([]);
      },
      (error) => {
        setError(error);
      },
    );
    return () => unsubscribe();
  }, [userId]);

  const updateNotificationReadStatus = useCallback(
    async ({
      readNotificationId,
      expiredAt,
    }: {
      readNotificationId: string;
      expiredAt: Timestamp;
    }): Promise<Result<undefined>> => {
      if (!userId) return { error: new Error("userId is not available") };
      if (!readStatusList) return { error: new Error("readStatusList is not found") };
      const readAt = Timestamp.now();
      const notification = readStatusList.find(
        ({ notificationId }) => notificationId === readNotificationId,
      );
      const readStatus = notification?.readStatus ?? [{ readAt, browserId }];
      const updatedReadStatus = notification
        ? [...readStatus.filter((status) => status.browserId !== browserId), { readAt, browserId }]
        : [{ readAt, browserId }];
      try {
        await setDoc(
          doc(firestore, "clients", userId),
          {
            userId,
            notifications: [
              ...readStatusList.filter(
                ({ notificationId }) => notificationId !== readNotificationId,
              ),
              { expiredAt, readStatus: updatedReadStatus, notificationId: readNotificationId },
            ],
          },
          { merge: true },
        );
        logEvent({
          type: "notification_read",
          meta: { accountId: userId, browserId, notificationId: readNotificationId },
        });
        return { data: undefined };
      } catch (e) {
        const error = e as FirestoreError;
        return { error };
      }
    },
    [browserId, logEvent, readStatusList, userId],
  );

  const readStatusNotificationsMap = useMemo(
    () =>
      new Map(readStatusList?.map((readStatus) => [readStatus.notificationId, readStatus]) ?? []),
    [readStatusList],
  );

  return {
    readStatusList,
    readStatusNotificationsMap,
    updateNotificationReadStatus,
    error,
    browserId,
  };
};
