import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

export type Feature =
  | "showChangeDateTimeField"
  | "showEnableAlertField"
  | "showEnableRemarkField"
  | "showDisableCallClerkField"
  | "showShouldMergeSlipOptionsFields"
  | "showEnableCustomerSegmentSelection"
  | "showWinboardConfig"
  | "showAllowChangeField"
  | "editOnSitePayment"
  | "addCategory"
  | "editCategory"
  | "deleteCategory"
  | "addMenu"
  | "editMenu"
  | "deleteMenu"
  | "editShopMenu"
  | "addOption"
  | "editOption"
  | "deleteOption"
  | "editOptionKitchenRoles"
  | "editMenuDealer"
  | "addChoice"
  | "editChoice"
  | "deleteChoice"
  | "addPlan"
  | "editPlan"
  | "deletePlan"
  | "addPlanOption"
  | "editPlanOption"
  | "deletePlanOption"
  | "addPlanChoice"
  | "editPlanChoice"
  | "deletePlanChoice"
  | "deleteGiftChoice"
  | "editGiftDisplaySettings"
  | "editGiftRole"
  | "deleteGift"
  | "deleteOnSitePaymentDetailTypes"
  | "deleteOnSitePaymentDiscountTypes"
  | "deleteInflowSourceTags"
  | "editShopMenuDisplaySettings"
  | "editShopPlanDisplaySettings"
  | "editShopPlanStock"
  | "editPlanDealer"
  | "deleteRole"
  | "deleteTable"
  | "deleteMessageDelivery"
  | "showAllDashboardAccountsOverCompanies"
  | "showAddDashboardAccountButton"
  | "createCorporationManagerAccount"
  | "editRoleFieldOnEditDashboardAccount"
  | "editOrderableTime"
  | "showEnableOfflinePayment"
  | "editBankDepositAmount"
  | "showUseKdField"
  | "showPikaichiConfig"
  | "showTecAggregationConfig"
  | "showFoodingJournalConfig"
  | "addMenuRecommendation"
  | "editMenuRecommendation"
  | "deletePikaichiMenu"
  | "deletePikaichiPlan"
  | "deleteWinboardMenu"
  | "deleteWinboardPlan"
  | "editTecMediaMap"
  | "deleteDashboardAccount"
  | "deleteDashboardAccountRole"
  | "addLineReportingBotConfig"
  | "editLineReportingBotConfig"
  | "editCompulsoryAppetizer"
  | "salesBudget"
  | "addSalesBudget"
  | "editSalesBudget"
  | "editSurchargeConfig"
  | "editMembershipCardAppearanceType"
  | "editMembershipRankRequiredVisitCount"
  | "editCloseCashRegisterTargetDate"
  | "archiveShop"
  | "editShopMenusBulk"
  | "editShopPlansBulk"
  | "showTimeAlertThresholdFields";

/*
 * serviceAdmin はすべての機能にアクセス可能
 * その判定は useIsFeatureEnabled で行うので、serviceAdmin のみアクセス可能な機能は undefined になっている
 */
export const featurePermissions: Record<
  Feature,
  { feature: DashboardFeatureEnum; type: DashboardAccountRolePermissionTypeEnum } | undefined
> = {
  showChangeDateTimeField: undefined,
  showEnableAlertField: undefined,
  showEnableRemarkField: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showDisableCallClerkField: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showShouldMergeSlipOptionsFields: undefined,
  showWinboardConfig: undefined,
  showPikaichiConfig: undefined,
  showFoodingJournalConfig: undefined,
  showAllowChangeField: undefined,
  editCompulsoryAppetizer: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOnSitePayment: {
    feature: DashboardFeatureEnum.AccountingHistory,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addCategory: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editCategory: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteCategory: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addMenu: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenu: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuDealer: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteMenu: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopMenu: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOptionKitchenRoles: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addPlan: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlan: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deletePlan: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addPlanOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deletePlanOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addPlanChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deletePlanChoice: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteGiftChoice: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editGiftDisplaySettings: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editGiftRole: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteGift: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteOnSitePaymentDiscountTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteInflowSourceTags: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopMenuDisplaySettings: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlanDisplaySettings: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlanStock: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanDealer: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteRole: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteTable: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteMessageDelivery: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showAllDashboardAccountsOverCompanies: undefined,
  showAddDashboardAccountButton: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  createCorporationManagerAccount: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editRoleFieldOnEditDashboardAccount: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOrderableTime: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showEnableCustomerSegmentSelection: undefined,
  showEnableOfflinePayment: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editBankDepositAmount: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showUseKdField: undefined,
  showTecAggregationConfig: undefined,
  addMenuRecommendation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuRecommendation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deletePikaichiMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deletePikaichiPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteWinboardMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteWinboardPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editTecMediaMap: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteDashboardAccount: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  deleteDashboardAccountRole: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addLineReportingBotConfig: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editLineReportingBotConfig: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  salesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addSalesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editSalesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editSurchargeConfig: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMembershipCardAppearanceType: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMembershipRankRequiredVisitCount: undefined,
  editCloseCashRegisterTargetDate: undefined,
  archiveShop: undefined,
  editShopMenusBulk: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlansBulk: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  showTimeAlertThresholdFields: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
};
