import React, { memo } from "react";
import { FormListFieldData } from "antd";
import { ColumnsType } from "antd/lib/table";

import { FormList, withFormDependencies } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";
import { Table } from "components/Table";

import { EditPlanTecAggregationPlanFormItem } from "../../useEditPlanTecAggregationPlanForm";

type Props = {
  optionField: FormListFieldData;
  loading?: boolean;
};

type FormListFieldDataSource = {
  planOptionField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
  planChoiceField: {
    name: FormListFieldData["name"];
    key: FormListFieldData["key"];
  };
};

export const TecAggregationPlanChoiceTable = memo<Props>(({ optionField, loading }) => {
  const columns: ColumnsType<FormListFieldDataSource> = [
    {
      title: "選択肢名",
      key: "optionName",
      fixed: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem.NonProperty
            noStyle
            shouldUpdate={withFormDependencies(({ plan }) => [
              plan?.planOptions.map((option) => {
                option.planChoices.map((choice) => choice.name);
              }),
            ])}
          >
            {({ getFieldValue }) => {
              const choiceName = getFieldValue([
                "plan",
                "planOptions",
                field.planOptionField.name,
                "planChoices",
                field.planChoiceField.name,
                "name",
              ]);

              return <span>{choiceName}</span>;
            }}
          </EditPlanTecAggregationPlanFormItem.NonProperty>
        );
      },
    },
    {
      title: "メニューコード",
      key: "menuCode",
      align: "left",
      width: 130,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "menuCode"]}
            rules={[
              {
                required: true,
                pattern: /^[1-9]\d{0,7}$/,
                message: "8桁以内の数字で入力してください",
              },
            ]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
    {
      title: "メニュー名",
      key: "name",
      align: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "name"]}
            rules={[{ required: true, max: 25, message: "25文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
    {
      title: "部門コード",
      key: "dpCode",
      align: "left",
      width: 100,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "dpCode"]}
            rules={[
              { required: true, pattern: /^\d{1,4}$/, message: "4桁以内の数字で入力してください" },
            ]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
    {
      title: "部門名",
      key: "dpName",
      align: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "dpName"]}
            rules={[{ required: true, max: 20, message: "20文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
    {
      title: "分類コード",
      key: "gpCode",
      align: "left",
      width: 100,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "gpCode"]}
            rules={[
              { required: true, pattern: /^\d{1,4}$/, message: "4桁以内の数字で入力してください" },
            ]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
    {
      title: "分類名",
      key: "gpName",
      align: "left",
      width: 150,
      render(_: unknown, field: FormListFieldDataSource) {
        return (
          <EditPlanTecAggregationPlanFormItem
            name={[field.planChoiceField.name, "tecAggregationMenu", "gpName"]}
            rules={[{ required: true, max: 20, message: "20文字以内で入力してください" }]}
            endSpacer={null}
          >
            <InputCode />
          </EditPlanTecAggregationPlanFormItem>
        );
      },
    },
  ];

  return (
    <FormList name={[optionField.name, "planChoices"]}>
      {(planChoiceFields) => (
        <Table
          rowKey="planChoiceId"
          columns={columns}
          dataSource={planChoiceFields.map((field) => ({
            planOptionField: optionField,
            planChoiceField: field,
          }))}
          loading={loading}
          bordered
          pagination={false}
        />
      )}
    </FormList>
  );
});
