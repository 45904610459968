import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditChoiceTecAggregationMenuFormItem } from "../useEditChoiceTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationDpCodeField = memo<Props>((props) => (
  <EditChoiceTecAggregationMenuFormItem
    label="部門コード"
    name="dpCode"
    rules={[{ required: true, pattern: /^\d{1,4}$/, message: "4桁以内の数字で入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditChoiceTecAggregationMenuFormItem>
));
