import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditChoiceTecAggregationMenuFormItem } from "../useEditChoiceTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationMenuCodeField = memo<Props>((props) => (
  <EditChoiceTecAggregationMenuFormItem
    label="メニューコード"
    name="menuCode"
    rules={[
      { required: true, pattern: /^[1-9]\d{0,7}$/, message: "8桁以内の数字で入力してください" },
    ]}
    {...props}
  >
    <InputCode />
  </EditChoiceTecAggregationMenuFormItem>
));
