import { MessageDeliveryCustomerSegmentDayOfWeekTypeEnum } from "types/graphql";

export const dayOfWeeks = ["月", "火", "水", "木", "金", "土", "日"];

export const DayOfWeek = {
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Mon]: "月",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Tue]: "火",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Wed]: "水",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Thu]: "木",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Fri]: "金",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sat]: "土",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sun]: "日",
  [MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Holiday]: "祝",
} as const;
export type DayOfWeek = (typeof DayOfWeek)[keyof typeof DayOfWeek];

export const DayOfWeekCheckboxOptions: {
  label: string;
  value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum;
}[] = [
  { label: "月", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Mon },
  { label: "火", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Tue },
  { label: "水", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Wed },
  { label: "木", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Thu },
  { label: "金", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Fri },
  { label: "土", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sat },
  { label: "日", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Sun },
  { label: "祝日", value: MessageDeliveryCustomerSegmentDayOfWeekTypeEnum.Holiday },
];
