import React, { memo, useCallback, useMemo, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Layout } from "antd";
import { getRole } from "models/user";

import { AppBar } from "components/Layout/DashboardLayout/AppBar";
import { CompanySelect } from "components/Layout/DashboardLayout/CompanySelect";
import {
  NavigationDrawer,
  useConstateNavigationDrawer,
} from "components/Layout/DashboardLayout/NavigationDrawer";
import {
  useDashboardLayoutGetCompanyQuery,
  useDashboardLayoutGetDashboardAccountQuery,
  useDashboardLayoutGetServiceAdminQuery,
} from "components/Layout/DashboardLayout/queries";
import { useCompany } from "hooks/useCompany";
import { useShop } from "hooks/useShop";
import { useShopPerCorporation } from "hooks/useShopPerCorporation";
import { useSignOut } from "hooks/useSignOut";
import { useCurrentUser } from "hooks/useUser";
import { Role } from "types/role";

const StyledLayout = styled(Layout)`
  height: 100%;
`;

type Props = {
  children: React.ReactNode;
};

export const NavigationLayout = memo<Props>(({ children }) => {
  const user = useCurrentUser();
  const role = getRole(user);
  const isServiceAdmin = role === Role.ServiceAdmin;

  const { data: getCompanyData } = useDashboardLayoutGetCompanyQuery(user ? {} : { skip: true });
  const [currentCompany, setCompanyId] = useCompany();
  const [_, setShopId] = useShop();
  const { setShop: setShopIdPerCorporation } = useShopPerCorporation();
  const companies = useMemo(() => getCompanyData?.company ?? [], [getCompanyData?.company]);
  const company = companies.find((c) => c.companyId === currentCompany?.companyId);

  const { data } = useDashboardLayoutGetDashboardAccountQuery(
    user?.email ? { variables: { email: user.email } } : { skip: true },
  );

  const { data: serviceAdminData } = useDashboardLayoutGetServiceAdminQuery(
    user?.email && isServiceAdmin ? { variables: { email: user?.email } } : { skip: true },
  );

  const accountName = data?.dashboardAccount?.[0]?.userName;

  const accountId = isServiceAdmin
    ? serviceAdminData?.serviceAdmin?.[0]?.serviceAdminId
    : data?.dashboardAccount?.[0]?.id;

  const accountAccessibleCompanies = useMemo(
    () => data?.dashboardAccount?.[0]?.dashboardAccountAccessibleCompanies ?? [],
    [data?.dashboardAccount],
  );

  const accountAccessibleShops = useMemo(
    () => data?.dashboardAccount?.[0]?.dashboardAccountAccessibleShops ?? [],
    [data?.dashboardAccount],
  );

  const { resetSelectedKey } = useConstateNavigationDrawer();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const setCompany = useCallback(
    (companyId: string) => {
      navigate("/");
      setCompanyId(companyId);
      // NOTE: company を変えた場合に現在設定されている shop が該当 company に含まれるか検討を行う必要があるが、現行の状態管理スキーマだと難しい
      // クライアントは複数店舗を跨ぐことは基本的にないので、company が変更されるたびに shop をリセットするという仕様にする
      setShopId(undefined);
      setShopIdPerCorporation(undefined);
      resetSelectedKey();
      onClose();
    },
    [navigate, onClose, resetSelectedKey, setCompanyId, setShopId, setShopIdPerCorporation],
  );

  const { signOut } = useSignOut();

  return (
    <StyledLayout>
      <AppBar
        title={company?.name}
        accountId={accountId}
        accountName={accountName}
        accountAccessibleCompanies={accountAccessibleCompanies}
        accountAccessibleShops={accountAccessibleShops}
        companies={companies}
        signOut={signOut}
        isOpenDrawer={open}
        onOpenDrawer={onOpen}
        onCloseDrawer={onClose}
      />
      <CompanySelect companies={companies} setCompany={setCompany} />
      <Layout>
        <NavigationDrawer
          accountName={accountName}
          open={open}
          onClose={onClose}
          companies={companies}
          setCompany={setCompany}
          signOut={signOut}
        />
        {children}
      </Layout>
      <Outlet />
      {/* <CustomerSummaryModal
          visible={isOpenCustomerSummaryModal}
          onCloseModal={closeCustomerSummaryModal}
        /> */}
    </StyledLayout>
  );
});
