import React, { memo } from "react";
import styled from "styled-components";
import { Select, Switch } from "antd";

import { colors } from "constants/colors";

import { DisplayNotificationCategory } from "../..";
import { notificationCategoryLabel } from "../../useNotifications";

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid ${colors.Divider.Default};
  box-shadow: 0px 3px 6px -4px #00000014;
`;

const SelectWarper = styled.div`
  width: 50%;
  height: 100%;
`;

const StyledSelect = styled(Select<DisplayNotificationCategory>)`
  width: 100%;
`;
const RadiobuttonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 0;
  width: "50%";
`;

const Label = styled.div`
  font-size: 14px;
  text-align: center;
  font-weight: 500;
`;

type Props = {
  handleCategoryChange: (key: DisplayNotificationCategory) => void;
  handleUnreadSwitch: (checked: boolean) => void;
  checked: boolean;
};

type SelectOption = { value: DisplayNotificationCategory; label: string };

const options: SelectOption[] = [
  { value: "all", label: "すべて" },
  { value: "highest", label: notificationCategoryLabel["highest"] },
  { value: "high", label: notificationCategoryLabel["high"] },
  { value: "middle", label: notificationCategoryLabel["middle"] },
  { value: "low", label: notificationCategoryLabel["low"] },
];
export const NotificationListModalHeader = memo<Props>(
  ({ handleCategoryChange, handleUnreadSwitch, checked }) => (
    <HeaderContent>
      <SelectWarper>
        <StyledSelect options={options} defaultValue="all" onChange={handleCategoryChange} />
      </SelectWarper>
      <RadiobuttonWrapper>
        <Label>未読のみ表示</Label>
        <Switch checked={checked} onChange={handleUnreadSwitch} />
      </RadiobuttonWrapper>
    </HeaderContent>
  ),
);
