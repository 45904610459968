import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanChoiceWinboardMenuFormItem } from "../useEditPlanChoiceWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const CategoryNameField = memo<Props>((props) => (
  <EditPlanChoiceWinboardMenuFormItem
    label="分類名"
    name="categoryName"
    rules={[{ max: 15, message: "15文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditPlanChoiceWinboardMenuFormItem>
));
