import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import { Button, Col, DatePicker, Flex, Row, TreeSelect } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { difference } from "lodash";
import { RangeValue } from "rc-picker/lib/interface";

import { Select } from "components/Input/Select";
import { viewport } from "constants/viewport";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";
import { useRangePresets } from "hooks/useRangePresets";
import { useViewport } from "hooks/useViewport";
import {
  QuestionnaireAnswererSatisfactionType,
  QuestionnaireAnswererSegmentType,
} from "types/graphql";

import { Company } from "../types";

export type FilterConditions = {
  shopIds?: string[];
  answerSegments?: QuestionnaireAnswererSegmentType[];
  range?: [dayjs.Dayjs | null, dayjs.Dayjs | null];
  answererSatisfactions?: QuestionnaireAnswererSatisfactionType[];
};

export type SerializedFilterConditions = {
  range?: [number | null, number | null] | undefined;
} & Omit<FilterConditions, "range">;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledRow = styled(Row).attrs({ gutter: [16, 16] })`
  width: 100%;
`;

const StyledCol = styled(Col).attrs({ span: 24, xl: 6 })`
  @media ${viewport.desktop} {
    min-width: 260px;
  }
`;

const RangePickerPanelContainer = styled.div`
  @media ${viewport.smartphone}, ${viewport.tablet} {
    .ant-picker-panels {
      flex-direction: column;
    }
  }
`;

const StyledTreeSelect = styled(TreeSelect<string[]>)`
  width: 100%;
`;

const questionnaireAnswererSegmentOptions = [
  { label: "新規", value: QuestionnaireAnswererSegmentType.NewCustomer },
  { label: "リピーター", value: QuestionnaireAnswererSegmentType.RepeatCustomer },
];

const questionnaireAnswererSatisfactionOptions = [
  { label: "満足度が高いコメント", value: QuestionnaireAnswererSatisfactionType.High },
  { label: "改善が必要なコメント", value: QuestionnaireAnswererSatisfactionType.Low },
];

type Props = {
  companies: Company[];
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  downloadQuestionnaireComments: () => void;
  selectedShopIds: string[];
  setSelectedShopIds: (value: string[]) => void;
  initializePage: () => void;
};

export const CommentFilter = memo<Props>(
  ({
    companies,
    filterConditions,
    updateFilterCondition,
    downloadQuestionnaireComments,
    selectedShopIds,
    setSelectedShopIds,
    initializePage,
  }) => {
    const handleUpdateShopIds = useCallback(
      () => updateFilterCondition({ shopIds: selectedShopIds }),
      [selectedShopIds, updateFilterCondition],
    );

    const handleChangeRange = useCallback(
      (values: RangeValue<dayjs.Dayjs>) => {
        const start = values?.[0]?.startOf("day") ?? null;
        const end = values?.[1]?.endOf("day") ?? null;

        updateFilterCondition({ range: [start, end] });
      },
      [updateFilterCondition],
    );

    const handleChangeAnswererSegment = useCallback(
      (answerSegments: QuestionnaireAnswererSegmentType[]) => {
        initializePage();
        updateFilterCondition({ answerSegments });
      },
      [initializePage, updateFilterCondition],
    );

    const handleChangeAnswererSatisfaction = useCallback(
      (answererSatisfactions: QuestionnaireAnswererSatisfactionType[]) => {
        initializePage();
        updateFilterCondition({ answererSatisfactions });
      },
      [initializePage, updateFilterCondition],
    );

    const shopTreeData = useMemo(
      () =>
        (
          companies
            .filter(({ shops }) => shops.length > 0)
            .sort((a, b) => a.name.localeCompare(b.name)) ?? []
        ).map((company) => ({
          key: company.id,
          value: company.id,
          title: company.name,
          selectable: true,
          children: company.shops
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((shop) => ({
              key: shop.shopId,
              value: shop.shopId,
              title: shop.name,
              selectable: true,
            })),
        })),
      [companies],
    );

    const isUpdateShopIdsButtonDisabled = useMemo(() => {
      const filterConditionShopIds = filterConditions.shopIds ?? [];

      return (
        selectedShopIds.length === 0 ||
        (selectedShopIds.length === filterConditionShopIds.length &&
          difference(selectedShopIds, filterConditionShopIds).length === 0)
      );
    }, [filterConditions.shopIds, selectedShopIds]);

    const { isDesktop } = useViewport();

    const { rangePresets } = useRangePresets();

    return (
      <>
        <StyledFlex gap={16} align="center">
          <StyledTreeSelect
            treeData={shopTreeData}
            treeDefaultExpandAll
            treeCheckable
            value={selectedShopIds}
            placeholder="店舗"
            maxTagCount="responsive"
            allowClear
            onChange={(value) => setSelectedShopIds(value)}
            onClear={() => setSelectedShopIds([])}
            treeNodeFilterProp="title"
          />
          <Button
            type="primary"
            disabled={isUpdateShopIdsButtonDisabled}
            onClick={handleUpdateShopIds}
          >
            更新
          </Button>
        </StyledFlex>
        <StyledFlex gap={16} justify="space-between" align="center">
          <StyledRow>
            <StyledCol>
              <DatePicker.RangePicker
                value={filterConditions.range}
                ranges={rangePresets}
                onCalendarChange={handleChangeRange}
                allowClear={false}
                style={{ width: "100%" }}
                panelRender={(panelNode) => (
                  <RangePickerPanelContainer>{panelNode}</RangePickerPanelContainer>
                )}
              />
            </StyledCol>
            <StyledCol>
              <Select<QuestionnaireAnswererSegmentType[]>
                allowClear
                placeholder="来店経験"
                value={filterConditions.answerSegments}
                onChange={handleChangeAnswererSegment}
                mode="multiple"
                options={questionnaireAnswererSegmentOptions}
                getPopupContainer={(trigger) => trigger.parentNode}
                style={{ width: "100%" }}
              />
            </StyledCol>
            <StyledCol>
              <Select<QuestionnaireAnswererSatisfactionType[]>
                allowClear
                placeholder="満足度"
                value={filterConditions.answererSatisfactions}
                onChange={handleChangeAnswererSatisfaction}
                mode="multiple"
                maxTagCount="responsive"
                options={questionnaireAnswererSatisfactionOptions}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </StyledCol>
          </StyledRow>
          {isDesktop && (
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={downloadQuestionnaireComments}
            >
              CSV ダウンロード
            </Button>
          )}
        </StyledFlex>
      </>
    );
  },
);
