import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditTecAggregationPlanFormItem } from "../useEditTecAggregationPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationGpNameField = memo<Props>((props) => (
  <EditTecAggregationPlanFormItem
    label="分類名"
    name="gpName"
    rules={[{ required: true, max: 20, message: "20文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditTecAggregationPlanFormItem>
));
