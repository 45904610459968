import { ApolloError } from "@apollo/client";
import { GraphQLErrorExtensions } from "graphql";

export const extractError = (error: ApolloError) => {
  const extensions = error.graphQLErrors[0]?.extensions as GraphQLErrorExtensions;

  if (extensions === undefined) return null;

  const response = extensions.exception.response;
  const title = response.title;
  const message = response.message;

  if (typeof title === "string" && typeof message === "string") return { title, message };

  return null;
};
