import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Col, Input, Row } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";

import { GmoBankAccountTable } from "./GmoBankAccountTable";
import { useGmoBankAccountGetGmoBankAccountsQuery } from "./queries";

const StyledInput = styled(Input)`
  width: 100%;
`;

type FilterConditions = {
  query: string;
};

export const GmoBankAccount = () => {
  const { data: gmoBankAccountsData, loading: loadingGmoBankAccounts } =
    useGmoBankAccountGetGmoBankAccountsQuery();

  const gmoBankAccounts = useMemo(
    () => gmoBankAccountsData?.gmoBankAccount ?? [],
    [gmoBankAccountsData],
  );

  const { filterConditions, updateFilterCondition } = useFilterConditions<FilterConditions>({
    query: "",
  });

  const filteredGmoBankAccounts = useMemo(
    () =>
      gmoBankAccounts.filter(
        (gmoBankAccount) =>
          !filterConditions.query || gmoBankAccount.name.includes(filterConditions.query),
      ),
    [gmoBankAccounts, filterConditions],
  );

  const loading = loadingGmoBankAccounts;

  return (
    <DashboardLayout title="法人口座設定">
      <PageHeader
        title="法人口座設定"
        extra={
          <Link to="/gmoBankAccount/add">
            <Button type="primary">新規作成</Button>
          </Link>
        }
      />
      <Row>
        <Col span={6}>
          <StyledInput
            placeholder="口座管理名"
            value={filterConditions.query}
            onChange={(event) => updateFilterCondition({ query: event.target.value })}
          />
        </Col>
      </Row>
      <Spacer size={24} />
      <GmoBankAccountTable gmoBankAccounts={filteredGmoBankAccounts} loading={loading} />
    </DashboardLayout>
  );
};
