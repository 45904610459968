import React, { memo } from "react";
import { InputNumber } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditEbicaConfigFormItem } from "../useEditEbicaConfigForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const EbicaShopIdField = memo<Props>((props) => (
  <EditEbicaConfigFormItem
    label="飲食店ID"
    required
    name="ebicaShopId"
    rules={[{ required: true, message: "飲食店IDを入力してください" }]}
    {...props}
  >
    <InputNumber controls={false} keyboard={false} />
  </EditEbicaConfigFormItem>
));
