import React, { memo } from "react";

import { FormItemProps } from "components/antd/Form";
import { InputCode } from "components/Input/InputCode";

import { EditPlanChoiceTecAggregationMenuFormItem } from "../useEditPlanChoiceTecAggregationMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const TecAggregationGpCodeField = memo<Props>((props) => (
  <EditPlanChoiceTecAggregationMenuFormItem
    label="分類コード"
    name="gpCode"
    rules={[{ required: true, pattern: /^\d{1,4}$/, message: "4桁以内の数字で入力してください" }]}
    {...props}
  >
    <InputCode />
  </EditPlanChoiceTecAggregationMenuFormItem>
));
