import React, { memo } from "react";
import { Select } from "antd";
import { FormItemProps } from "antd/lib";

import { AssignShopCashlessSettingTerminalIdFormItem } from "../useAssignShopCashlessSettingTerminalIdForm";

const ADYEN_TERMINAL_MODELS = ["AMS1", "S1F2"];

type Props = Omit<FormItemProps, "children" | "name">;

export const ModelField = memo<Props>((props) => (
  <AssignShopCashlessSettingTerminalIdFormItem
    label="端末モデル"
    name="model"
    rules={[{ required: true, message: "端末モデルを選択してください" }]}
    required
    {...props}
  >
    <Select>
      {ADYEN_TERMINAL_MODELS.map((model) => (
        <Select.Option key={model} value={model}>
          {model}
        </Select.Option>
      ))}
    </Select>
  </AssignShopCashlessSettingTerminalIdFormItem>
));
