import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import type { RankingData } from "models/customerDashboard";

import { CustomerRankingTable } from "components/CustomerRanking/CustomerRankingTable";
import { Loading } from "components/Loading";
import { colors } from "constants/colors";

const Wrapper = styled.div`
  background-color: #ffffff;
`;

const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.Border.Default};
`;

const ArrowLeftIcon = styled(ArrowLeftOutlined)`
  margin-right: 8px;
  svg {
    fill: rgba(0, 0, 0, 0.85);
  }
`;

const HeaderText = styled.p`
  font-size: 20px;
  color: ${colors.Text.Default};
  font-weight: bold;
  margin-bottom: 0px;
`;

const TableWrapper = styled.div`
  padding: 0px 16px;
  min-height: 480px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 16px 32px;
`;

type Props = {
  rankingData: RankingData;
  loading: boolean;
  title: string;
  valueUnit: string;
  currentPage?: number;
  pageSize?: number;
  totalRankingCount?: number;
  onChangePage?: ((page: number, pageSize: number) => void) | undefined;
  onShowSizeChange?: ((current: number, size: number) => void) | undefined;
};

// TODO: 他のランキング系のページもBE側でのページネーションを実装したタイミングでCustomerRankingに再度戻す
export const RepeaterRanking = memo<Props>(
  ({
    rankingData,
    loading,
    title,
    valueUnit,
    currentPage,
    pageSize,
    totalRankingCount,
    onChangePage,
    onShowSizeChange,
  }) => (
    <Wrapper>
      <Header>
        <Link to="/">
          <ArrowLeftIcon />
        </Link>
        <HeaderText>{title}</HeaderText>
      </Header>

      {loading ? (
        <Loading height={586} />
      ) : (
        <>
          <TableWrapper>
            <CustomerRankingTable rankingData={rankingData} valueUnit={valueUnit} />
          </TableWrapper>

          <PaginationWrapper>
            <Pagination
              pageSize={pageSize}
              onChange={onChangePage}
              current={currentPage}
              onShowSizeChange={onShowSizeChange}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} 人`}
              total={totalRankingCount}
              showSizeChanger
            />
          </PaginationWrapper>
        </>
      )}
    </Wrapper>
  ),
);
