import { useEffect } from "react";
import { isSoldOut } from "models/stock";
import { isNotUndefined } from "util/type/primitive";

import type {
  BulkEditConditions,
  BulkEditConditionsEntries,
  DisplayedShop,
  Entries,
} from "./types";

const includeKeys = ["isVisibleForCustomer", "isVisibleForStaff"] as const;
type IncludeKeys = (typeof includeKeys)[number];

const filterCondition = (
  entry: BulkEditConditionsEntries[number],
): entry is Entries<Required<Pick<BulkEditConditions, IncludeKeys>>>[number] => {
  const [key, value] = entry;
  return includeKeys.some((v) => v === key) && isNotUndefined(value);
};

export const useApplyBulkEditConditions = ({
  bulkEditConditions,
  currentPageSelectedShopIds,
  initialDisplayedShops,
  menuId,
  _menuId,
  setDisplayedShops,
}: {
  bulkEditConditions: BulkEditConditions;
  currentPageSelectedShopIds: string[];
  initialDisplayedShops: DisplayedShop[];
  setDisplayedShops: React.Dispatch<React.SetStateAction<DisplayedShop[]>>;
  menuId: string;
  _menuId: number;
}) => {
  useEffect(() => {
    const shopMenuAppliedConditions = Object.fromEntries(
      (Object.entries(bulkEditConditions) as BulkEditConditionsEntries).filter(filterCondition),
    );

    const nextShops = initialDisplayedShops.map<DisplayedShop>((shop) => {
      if (!currentPageSelectedShopIds.includes(shop.shopId)) return shop;

      const defaultShopMenu: DisplayedShop["shopMenu"] = {
        id: null,
        shop,
        shopId: shop.shopId,
        menuId,
        _menuId,
        isVisibleForCustomer: true,
        isVisibleForStaff: true,
        stock: null,
        shopMenuKitchenRoles: [],
        dishUpSlipGroupShopMenus: [],
        shopMenuKdDisplayTargets: [],
      };

      const shopMenu =
        shop.shopMenu ?? (bulkEditConditions.isDealing ? defaultShopMenu : undefined);

      if (!shopMenu || bulkEditConditions.isDealing === false) {
        return { ...shop, shopMenu: undefined };
      }

      const soldOut = bulkEditConditions.isSoldOut ?? isSoldOut({ stock: shopMenu.stock });
      const defaultCurrentStockNum =
        shopMenu.stock?.currentStockNum !== 0 ? shopMenu.stock?.currentStockNum : null;

      const nextStock = {
        id: shopMenu.stock?.id,
        currentStockNum: soldOut ? 0 : bulkEditConditions.currentStockNum ?? defaultCurrentStockNum,
        dailyStockNum: bulkEditConditions.dailyStockNum ?? shopMenu.stock?.dailyStockNum,
      };

      return {
        ...shop,
        shopMenu: {
          ...shopMenu,
          ...shopMenuAppliedConditions,
          stock: nextStock,
        },
      };
    });

    setDisplayedShops(nextShops);
  }, [
    bulkEditConditions,
    currentPageSelectedShopIds,
    initialDisplayedShops,
    menuId,
    _menuId,
    setDisplayedShops,
  ]);
};
