import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Alert, Breadcrumb } from "antd";
import dayjs from "dayjs";
import { isNotNullable } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";
import { useGmoBankAccountDetails } from "hooks/useGmoBankAccountDetails";

import { AdyenPaymentDepositFilter, FilterConditions } from "./AdyenPaymentDepositFilter";
import { AdyenPaymentDepositHistoryTable } from "./AdyenPaymentDepositHistoryTable";
import {
  useAdyenPaymentDepositHistoryByShop,
  useShopDetails,
} from "./useAdyenPaymentDepositHistoryByShop";

const RemittanceMetaContainer = styled.dl`
  width: 100%%;
  display: flex;
  justify-content: start;
  align-items: center;
  & > dd {
    margin-bottom: 0;
  }
`;

export const AdyenPaymentDepositHistoryByShop = () => {
  const { id: remittanceResultId } = useParams<{ id?: string }>();

  const { filterConditions, updateFilterCondition } = useFilterConditions<FilterConditions>({
    shopIds: [],
  });

  const {
    depositByShop,
    loading: loadingDepositHistory,
    error: getDepositHistoryError,
    gmoBankAccountIds,
  } = useAdyenPaymentDepositHistoryByShop({
    remittanceResultId,
    onFetchCompleted: ({ shopIds }) => {
      // NOTE: 最初は全選択
      updateFilterCondition({ shopIds });
    },
  });

  const {
    getGmoBankAccountDetail,
    loading: loadingGmoBankAccountDetails,
    error: gmoBankAccountDetailsError,
  } = useGmoBankAccountDetails({
    gmoBankAccountIds,
  });

  const shopIds = useMemo(() => depositByShop.map((deposit) => deposit.shopId), [depositByShop]);

  const {
    getShopDetail,
    loading: loadingShopDetails,
    error: getShopDetailsError,
  } = useShopDetails({ shopIds });

  const loading = loadingDepositHistory || loadingGmoBankAccountDetails || loadingShopDetails;
  const error = getDepositHistoryError || gmoBankAccountDetailsError || getShopDetailsError;

  const remittanceMeta = useMemo(() => {
    // NOTE: remittanceResult はすべて共通なので、最初の要素から取得する
    const representDeposit = depositByShop[0];
    if (loading || !representDeposit) {
      return {
        remittedAt: "",
        accountName: "",
        startingDate: "",
        closingDate: "",
      };
    }
    const bankAccountDetail = getGmoBankAccountDetail(representDeposit.gmoBankAccountId);
    const accountName = bankAccountDetail
      ? "口座番号: " + bankAccountDetail?.accountNumber
      : "不明な口座";
    const remittedAt = dayjs(representDeposit.remittedAt).format("YYYY/MM/DD");
    const startingDate = dayjs(representDeposit.payoutJobSchedule.startingDate).format(
      "YYYY/MM/DD",
    );
    const closingDate = dayjs(representDeposit.payoutJobSchedule.closingDate).format("YYYY/MM/DD");

    return {
      remittedAt,
      accountName,
      startingDate,
      closingDate,
    };
  }, [loading, depositByShop, getGmoBankAccountDetail]);

  const depositHistoriesWithGmoBankAccountAndShop = useMemo(
    () =>
      depositByShop
        .map((deposit) => {
          const gmoBankAccount = getGmoBankAccountDetail(deposit.gmoBankAccountId);
          const shop = getShopDetail({ shopId: deposit.shopId });
          return {
            ...deposit,
            ...deposit.payoutJobSchedule,
            // NOTE: 口座名・店舗名が出ないことより送金金額を正しく表示できる方が重要なので、空文字で出すようにし filter はしない
            // 口座も店舗も先に登録しているので、取得できないことは基本ない
            bankAccountName: gmoBankAccount?.accountNumber ?? "不明な口座",
            shopName: shop?.name ?? "不明な店舗",
            closingDepositId: deposit.id,
          };
        })
        .filter(isNotNullable)
        .sort((a, b) => dayjs(b.totalAmount).diff(dayjs(a.totalAmount))),
    [depositByShop, getGmoBankAccountDetail, getShopDetail],
  );

  const filteredDepositHistories = useMemo(
    () =>
      depositHistoriesWithGmoBankAccountAndShop.filter(
        (depositHistory) =>
          !filterConditions.shopIds || filterConditions.shopIds.includes(depositHistory.shopId),
      ),
    [depositHistoriesWithGmoBankAccountAndShop, filterConditions],
  );

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <DashboardLayout title="入金履歴一覧">
      <Breadcrumb items={[{ title: "入金履歴一覧" }, { title: "入金詳細" }]} />
      <Spacer size={16} />
      <PageHeader title={remittanceMeta.accountName} onBack={goBack} footer={null} />
      <Spacer size={8} />
      <RemittanceMetaContainer>
        <dt>振込申請日：</dt>
        <dd>
          <b>{remittanceMeta.remittedAt}</b>
        </dd>
        <Spacer size={16} />
        <dt>起算日：</dt>
        <dd>
          <b>{remittanceMeta.startingDate}</b>
        </dd>
        <Spacer size={16} />
        <dt>締め日：</dt>
        <dd>
          <b>{remittanceMeta.closingDate}</b>
        </dd>
      </RemittanceMetaContainer>
      <Spacer size={16} />
      <AdyenPaymentDepositFilter
        shopIds={shopIds}
        getShopDetail={getShopDetail}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
      />
      <Spacer size={24} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AdyenPaymentDepositHistoryTable
        loading={loading}
        accountName={remittanceMeta.accountName}
        depositHistories={filteredDepositHistories}
      />
    </DashboardLayout>
  );
};
