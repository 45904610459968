/**
 * CSV 出力のフォーマットの種類の名称
 */
export const csvNames = [
  "timeSummary",
  "visitedTimeSummary",
  "orders",
  "canceledOrders",
  "orderSummary",
  "payments",
  "onlinePaymentTransaction",
  "discounts",
  "withdrawAndDeposit",
  "customerInflowSourceTag",
  "customerSegment",
  "kdServedTime",
  "kioskServedTime",
  "receipt",
  "audit",
  "transactions",
] as const;

export type CsvName = (typeof csvNames)[number];

export const csvNamesByShops = ["summaryByShops"] as const;

export type CsvNameByShops = (typeof csvNamesByShops)[number];
