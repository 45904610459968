import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditWinboardMenuFormItem } from "../useEditWinboardMenuForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const BumonNameField = memo<Props>((props) => (
  <EditWinboardMenuFormItem
    label="部門名"
    name="bumonName"
    rules={[{ max: 15, message: "15文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditWinboardMenuFormItem>
));
