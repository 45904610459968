import React, { memo, useMemo } from "react";
import { Radio } from "antd";
import { dashboardFeatures } from "models/dashboardFeature";

import { FormHelp } from "components/Form/FormHelp";
import { Table } from "components/Table";
import { PermissionLabel } from "constants/dashboardAccountRolePermissionLabels";
import { Permissions } from "pages/AddDashboardAccountRole/AddDashboardAccountRoleForm/useAddDashboardAccountRoleForm";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

const features = Object.entries(dashboardFeatures).map(([feature, name]) => ({
  feature: feature as DashboardFeatureEnum,
  name,
}));

type Feature = (typeof features)[number];

type Record = Feature & { type: DashboardAccountRolePermissionTypeEnum | undefined };

type Props = {
  permissions: Permissions;
  setPermission: ({
    feature,
    type,
  }: {
    feature: DashboardFeatureEnum;
    type: DashboardAccountRolePermissionTypeEnum | undefined;
  }) => void;
};

export const PermissionsField = memo<Props>(({ permissions, setPermission }) => {
  const columns = [
    {
      title: "操作権限",
      render(_: unknown, { name }: Record) {
        return name;
      },
    },
    {
      title: <FormHelp {...PermissionLabel.Editable} />,
      align: "center",
      render(_: unknown, { feature }: Record) {
        const type = permissions[feature];
        return (
          <Radio
            checked={type === DashboardAccountRolePermissionTypeEnum.Edit}
            onClick={() =>
              setPermission({ feature, type: DashboardAccountRolePermissionTypeEnum.Edit })
            }
          />
        );
      },
    } as const,
    {
      title: <FormHelp {...PermissionLabel.Viewable} />,
      align: "center",
      render(_: unknown, { feature }: Record) {
        const type = permissions[feature];
        return (
          <Radio
            checked={type === DashboardAccountRolePermissionTypeEnum.View}
            onClick={() =>
              setPermission({ feature, type: DashboardAccountRolePermissionTypeEnum.View })
            }
          />
        );
      },
    } as const,
    {
      title: <FormHelp {...PermissionLabel.NotViewable} />,
      align: "center",
      render(_: unknown, { feature }: Record) {
        const type = permissions[feature];
        return (
          <Radio checked={!type} onClick={() => setPermission({ feature, type: undefined })} />
        );
      },
    } as const,
  ];

  const dataSource: Record[] = useMemo(
    () =>
      features.map(({ feature, name }) => ({
        feature,
        name,
        type: permissions[feature],
      })),
    [permissions],
  );

  return (
    <Table rowKey="id" columns={columns} dataSource={dataSource} bordered pagination={false} />
  );
});
