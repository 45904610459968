import { useCallback } from "react";

import { useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery } from "./queries";

export const useGmoBankAccountDetails = ({
  gmoBankAccountIds,
}: {
  gmoBankAccountIds: string[];
}) => {
  const { data, loading, error } = useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery({
    variables: {
      input: {
        gmoBankAccountIds,
      },
    },
  });
  const getGmoBankAccountDetail = useCallback(
    (gmoBankAccountId: string) =>
      data?.onlinePaymentRoot?.gmoBankAccountDetails.gmoBankAccounts.find(
        ({ gmoBankAccountId: id }) => id === gmoBankAccountId,
      ),
    [data],
  );
  return { getGmoBankAccountDetail, loading, error };
};
