import React, { memo, ReactNode } from "react";
import styled from "styled-components";

type Props = {
  availableFrom: string;
  children?: ReactNode;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AvailableFromLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #00000040;
`;

export const AvailableFrom = memo<Props>(({ availableFrom, children }) => (
  <Wrapper>
    <AvailableFromLabel>{availableFrom}</AvailableFromLabel>
    {children}
  </Wrapper>
));
