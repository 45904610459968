import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditPlanTecAggregationPlanFormItem } from "../useEditPlanTecAggregationPlanForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const NameField = memo<Props>((props) => (
  <EditPlanTecAggregationPlanFormItem
    label="メニュー名称"
    name="name"
    rules={[{ required: true, max: 25, message: "25文字以内で入力してください" }]}
    {...props}
  >
    <Input />
  </EditPlanTecAggregationPlanFormItem>
));
