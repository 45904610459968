import dayjs from "dayjs";

import type { CustomerFaveYellRanking, CustomerRanking } from "types/graphql";

const DEFAULT_PAGE_SIZE = 10;

export type RankingData = {
  customerId: string;
  lastVisitedAt: string;
  lineProfileImage: string;
  lineProfileName: string;
  value: string;
  rank: number;
}[];

export const convertToRanking = ({
  customers,
  currentPage,
  pageSize,
}: {
  customers: CustomerRanking["customers"] | undefined;
  currentPage: number | undefined;
  pageSize: number | undefined;
}): RankingData => {
  if (!customers) return [];

  const formatter = new Intl.NumberFormat("ja");

  return customers.map((customer, index) => ({
    customerId: customer.customerId,
    lastVisitedAt: dayjs(customer.lastVisitedAt).format("YYYY/M/D H:mm"),
    lineProfileImage: customer.lineProfileImage,
    lineProfileName: customer.lineProfileName,
    value: formatter.format(customer.value),
    rank: ((currentPage ?? 1) - 1) * (pageSize ?? DEFAULT_PAGE_SIZE) + index + 1,
  }));
};

export const convertToFaveYellRanking = (
  customers: CustomerFaveYellRanking["customers"] | undefined,
): RankingData => {
  if (!customers) return [];

  const formatter = new Intl.NumberFormat("ja");

  return customers.map((customer, index) => ({
    customerId: customer.customerId,
    lastVisitedAt: dayjs(customer.lastFaveYellCreatedAt).format("YYYY/M/D H:mm"),
    lineProfileImage: customer.lineProfileImage,
    lineProfileName: customer.lineProfileName,
    value: formatter.format(customer.value),
    rank: index + 1,
  }));
};
