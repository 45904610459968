import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { DashboardFeatureEnum } from "types/graphql";

type Props = { company?: Company } & SubMenuPropsWithoutFeatures;

export const AdyenTerminalPaymentMenu = memo<Props>(({ company, ...props }) => (
  <SubMenu
    key="terminalPayment"
    title="ダイニーキャッシュレス"
    features={[DashboardFeatureEnum.TerminalPayment]}
    {...props}
  >
    <MenuItem
      route="adyenTerminalPaymentHistory"
      to="/terminalPayment/history"
      text="端末決済履歴一覧"
    />
    <MenuItem
      route="adyenTerminalPaymentDepositHistory"
      to="/terminalPayment/depositHistory"
      text="入金履歴一覧"
    />
  </SubMenu>
));
