import React, { memo } from "react";

import { MenuItem } from "components/Layout/DashboardLayout/NavigationDrawer/MenuItem";
import {
  SubMenu,
  SubMenuPropsWithoutFeatures,
} from "components/Layout/DashboardLayout/NavigationDrawer/SubMenu";
import { Company } from "components/Layout/DashboardLayout/types";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { DashboardFeatureEnum, FeatureFlagEnum } from "types/graphql";

type Props = {
  company?: Company;
} & SubMenuPropsWithoutFeatures;

export const PikaichiMenu = memo<Props>(({ company, ...props }) => {
  const shopIds = company?.shops.map((s) => s.shopId) ?? [];
  const { isFeatureAvailable } = useFeatureFlag();
  const canShowYellMenu = isFeatureAvailable({ name: FeatureFlagEnum.Yell, shopIds });

  return (
    <SubMenu
      key="pikaichi"
      title="ぴかいちナビ連携"
      features={[DashboardFeatureEnum.BusinessIntelligenceIntegration]}
      {...props}
    >
      <MenuItem route="pikaichiUpload" to="/pikaichi/upload" text="連携処理" />
      <MenuItem route="editPikaichiConfig" to="/pikaichi/config" text="設定" />
      <MenuItem
        route="pikaichiOnSitePaymentTypes"
        to="/pikaichi/onSitePaymentTypes"
        text="支払・割引方法設定"
      />
      <MenuItem route="pikaichiMenus" to="/pikaichi/menu" text="メニュー" />
      <MenuItem route="pikaichiPlans" to="/pikaichi/plan" text="プラン" />
      <MenuItem
        route="pikaichiGifts"
        to="/pikaichi/gift"
        text="推しエール"
        canShow={canShowYellMenu}
      />
      <MenuItem route="menuCodeCsv" to="/menuCodeCsv/pikaichi" text="メニューコード一括編集" />
    </SubMenu>
  );
});
