import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AdyenPaymentDepositHistoryGetGmoBankAccountDetails = gql`
    query AdyenPaymentDepositHistoryGetGmoBankAccountDetails($input: DashboardAccountGmoBankAccountDetailsInput!) {
  onlinePaymentRoot {
    gmoBankAccountDetails(input: $input) {
      gmoBankAccounts {
        gmoBankAccountId
        bankCode
        branchCode
        accountType
        accountNumber
        accountName
      }
    }
  }
}
    `;
export type AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables = Types.Exact<{
  input: Types.DashboardAccountGmoBankAccountDetailsInput;
}>;


export type AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery = (
  { __typename?: 'query_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootQuery' }
    & { gmoBankAccountDetails: (
      { __typename?: 'DashboardAccountGmoBankAccountDetailsResult' }
      & { gmoBankAccounts: Array<(
        { __typename?: 'GmoBankAccountDetail' }
        & Pick<Types.GmoBankAccountDetail, 'gmoBankAccountId' | 'bankCode' | 'branchCode' | 'accountType' | 'accountNumber' | 'accountName'>
      )> }
    ) }
  )> }
);


export const AdyenPaymentDepositHistoryGetGmoBankAccountDetailsDocument = gql`
    query AdyenPaymentDepositHistoryGetGmoBankAccountDetails($input: DashboardAccountGmoBankAccountDetailsInput!) {
  onlinePaymentRoot {
    gmoBankAccountDetails(input: $input) {
      gmoBankAccounts {
        gmoBankAccountId
        bankCode
        branchCode
        accountType
        accountNumber
        accountName
      }
    }
  }
}
    `;

/**
 * __useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery, AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery, AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables>(AdyenPaymentDepositHistoryGetGmoBankAccountDetailsDocument, options);
      }
export function useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery, AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery, AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables>(AdyenPaymentDepositHistoryGetGmoBankAccountDetailsDocument, options);
        }
export type AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery>;
export type AdyenPaymentDepositHistoryGetGmoBankAccountDetailsLazyQueryHookResult = ReturnType<typeof useAdyenPaymentDepositHistoryGetGmoBankAccountDetailsLazyQuery>;
export type AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryResult = Apollo.QueryResult<AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQuery, AdyenPaymentDepositHistoryGetGmoBankAccountDetailsQueryVariables>;