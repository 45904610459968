import React, { memo, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, List } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Timestamp } from "firebase/firestore";

import { TransformNotification } from "components/Layout/DashboardLayout/AppBar/Notification/";
import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { colors } from "constants/colors";

import { AvailableFrom } from "../../Row/AvailableFrom";
import { Category } from "../../Row/Category";
import { Targets } from "../../Row/Targets";
import { Title } from "../../Row/Title";

const LoadMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

const LoadMoreButton = styled(Button)`
  padding: 0;
  color: ${colors.Text.Primary};
  font-weight: 700;
`;

const NotificationItem = styled.li<{ isUnRead: boolean }>`
  display: flex;
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  padding: 16px;
  max-height: 168px;
  cursor: pointer;
  background-color: ${({ isUnRead }) => (isUnRead ? "transparent" : "#F5F5F5")};
`;

const ItemsReftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 4px;
`;

const ReadLabel = styled.span`
  color: #00000040;
`;

const ItemsRightWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  height: auto;
  align-items: center;
  width: 10%;
`;
const RightOutlinedIcon = styled(RightOutlined)`
  width: 16px;
  height: 16px;
`;

const displayNotificationIncrement = 10;

type Props = {
  notifications: TransformNotification[];
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  onClickHandle: (id: string, readAt: Timestamp) => void;
};

export const NotificationListModalContent = memo<Props>(
  ({ notifications, accountAccessibleCompanies, accountAccessibleShops, onClickHandle }) => {
    const [showingNotificationsCount, setShowingNotificationCount] = useState(
      displayNotificationIncrement,
    );

    const readMore = useCallback(
      () =>
        setShowingNotificationCount(
          (previousDisplayCount) => previousDisplayCount + displayNotificationIncrement,
        ),
      [],
    );

    const displayNotifications = useMemo(
      () => notifications.slice(0, showingNotificationsCount),
      [notifications, showingNotificationsCount],
    );

    return (
      <List
        itemLayout="horizontal"
        dataSource={displayNotifications}
        loadMore={
          displayNotifications.length !== notifications.length && (
            <LoadMoreWrapper>
              <LoadMoreButton type="text" onClick={readMore}>
                もっと見る
              </LoadMoreButton>
            </LoadMoreWrapper>
          )
        }
        renderItem={(notification) => (
          <NotificationItem
            isUnRead={notification.isUnread}
            onClick={() => onClickHandle(notification.id, notification.availableUntil)}
          >
            <ItemsReftContainer>
              <Category isImportant={notification.isImportant} category={notification.category} />
              <Title
                title={notification.title}
                fontSize={14}
                fontWeight={notification.isUnread ? "bold" : "normal"}
              />
              <Targets
                targets={notification.targets}
                accountAccessibleCompanies={accountAccessibleCompanies}
                accountAccessibleShops={accountAccessibleShops}
              />
              <AvailableFrom availableFrom={notification.availableFrom}>
                {!notification.isUnread && <ReadLabel>既読</ReadLabel>}
              </AvailableFrom>
            </ItemsReftContainer>
            <ItemsRightWrapper>
              <RightOutlinedIcon />
            </ItemsRightWrapper>
          </NotificationItem>
        )}
      ></List>
    );
  },
);
