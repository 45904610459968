import { useMemo } from "react";
import dayjs from "dayjs";
import { groupBy, sumBy } from "util/array";
import { isNotNullable } from "util/type/primitive";

import { useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery } from "./queries";

const useAdyenPaymentDepositHistoryByShop = ({
  corporationId,
  remittedAtFrom,
  remittedAtTo,
}: {
  corporationId: string | undefined;
  remittedAtFrom: dayjs.Dayjs | undefined;
  remittedAtTo: dayjs.Dayjs | undefined;
}) => {
  const {
    data,
    loading: loadingRemittanceResult,
    error: getRemittanceResultError,
  } = useAdyenPaymentDepositHistoryGetAdyenPaymentRemittanceResultByCorporationIdQuery(
    corporationId && remittedAtFrom && remittedAtTo
      ? {
          variables: {
            corporationId,
            remittedAtFrom: remittedAtFrom.format("YYYY-MM-DD"),
            remittedAtTo: remittedAtTo.add(1, "day").format("YYYY-MM-DD"),
          },
        }
      : { skip: true },
  );

  const depositByShop = useMemo(() => {
    const adyenPaymentRemittanceResults = data?.adyenPaymentRemittanceResult ?? [];
    // NOTE: 各送金 = adyenPaymentRemittanceResult をもとに、店舗ごとの送金情報 = adyenPaymentClosingDeposit を取得する
    // 店舗ごとの送金情報に、送金結果情報 = gmoBankAccount, remittanceStatus, remittedAt も付与する
    return adyenPaymentRemittanceResults.flatMap((result) =>
      result.adyenPaymentRemittanceClosingDeposits.map((closingDeposit) => ({
        ...closingDeposit.adyenPaymentClosingDeposit,
        gmoBankAccountId: result.gmoBankAccountId,
        remittanceStatus: result.remittanceStatus,
        remittedAt: result.remittedAt,
        remittanceResultId: result.id,
      })),
    );
  }, [data]);

  const gmoBankAccountIds = useMemo(
    () => depositByShop.map(({ gmoBankAccountId }) => gmoBankAccountId),
    [depositByShop],
  );

  return {
    depositByShop,
    loading: loadingRemittanceResult,
    error: getRemittanceResultError,
    gmoBankAccountIds,
  };
};

export const useAdyenPaymentDepositHistoryByRemittance = ({
  corporationId,
  remittedAtFrom,
  remittedAtTo,
}: {
  corporationId: string | undefined;
  remittedAtFrom: dayjs.Dayjs | undefined;
  remittedAtTo: dayjs.Dayjs | undefined;
}) => {
  const { depositByShop, loading, error, gmoBankAccountIds } = useAdyenPaymentDepositHistoryByShop({
    corporationId,
    remittedAtFrom,
    remittedAtTo,
  });

  const groupedByRemittanceId = useMemo(
    () => groupBy(depositByShop, (depositByShop) => depositByShop.remittanceResultId),
    [depositByShop],
  );
  const remittanceResultIds = useMemo(
    () => Object.keys(groupedByRemittanceId),
    [groupedByRemittanceId],
  );

  // NOTE: 店舗ごとの送金結果を合計し、口座ごとの送金結果としてまとめる
  // （remittanceResult 自体には feeAmount などがないため、店舗ごとのデータから合計して得る）
  const depositHistoriesByRemittance = useMemo(
    () =>
      remittanceResultIds
        .map((remittanceResultId) => {
          const shopClosingDeposits = groupedByRemittanceId[remittanceResultId];

          // NOTE: 店舗ごとの送金結果内の口座情報やスケジュールはすべて一致しているので、代表で一つピックする
          const representShopClosingDeposit = shopClosingDeposits?.[0];

          if (!representShopClosingDeposit) {
            return;
          }
          const totalAmount = sumBy(
            shopClosingDeposits,
            (closingDeposit) => closingDeposit.totalAmount,
          );
          const totalFeeAmount = sumBy(
            shopClosingDeposits,
            (closingDeposit) => closingDeposit.totalFeeAmount,
          );
          const totalNetAmount = sumBy(
            shopClosingDeposits,
            (closingDeposit) => closingDeposit.totalNetAmount,
          );

          return {
            remittanceResultId,
            gmoBankAccountId: representShopClosingDeposit.gmoBankAccountId,
            remittanceStatus: representShopClosingDeposit.remittanceStatus,
            remittedAt: representShopClosingDeposit.remittedAt,
            totalAmount,
            totalFeeAmount,
            totalNetAmount,
            payoutJobSchedule: representShopClosingDeposit.payoutJobSchedule,
          };
        })
        .filter(isNotNullable),
    [groupedByRemittanceId, remittanceResultIds],
  );

  return {
    depositHistoriesByRemittance,
    loading,
    error,
    gmoBankAccountIds,
  };
};
