import { useCallback, useEffect, useMemo } from "react";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { createFormItem, Form } from "components/antd/Form";

import { AdyenTerminalAssignmentHistory } from "../types";

export type AssignShopCashlessSettingTerminalIdFormValues = {
  model: string;
  serialNumber: string;
};

export const AssignShopCashlessSettingTerminalIdFormItem =
  createFormItem<AssignShopCashlessSettingTerminalIdFormValues>();

export const useAssignShopCashlessSettingTerminalIdForm = ({
  initialAdyenTerminalAssignmentHistory,
  onSubmit,
  onFormValidationError,
}: {
  initialAdyenTerminalAssignmentHistory: Pick<AdyenTerminalAssignmentHistory, "terminalId"> | null;
  onSubmit: (values: AssignShopCashlessSettingTerminalIdFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
}) => {
  const [form] = Form.useForm<AssignShopCashlessSettingTerminalIdFormValues>();
  const initialValues = useMemo(() => {
    const [model, serialNumber] =
      initialAdyenTerminalAssignmentHistory?.terminalId?.split("-") ?? [];
    return {
      model,
      serialNumber,
    };
  }, [initialAdyenTerminalAssignmentHistory]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const submit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      onSubmit(values);
    } catch (e) {
      if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
    }
  }, [form, onFormValidationError, onSubmit]);

  return { form, initialValues, submit };
};
