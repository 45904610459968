import React, { memo } from "react";
import styled from "styled-components";
import { Modal } from "antd";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { viewport } from "constants/viewport";
import { useViewport } from "hooks/useViewport";

import { AvailableFrom } from "../Row/AvailableFrom";
import { Category } from "../Row/Category";
import { Message } from "../Row/Message";
import { Targets } from "../Row/Targets";
import { Title } from "../Row/Title";
import { TransformNotification } from "..";

const StyledModal = styled(Modal)<{ isDesktop: boolean }>`
  padding: 0px;
  .ant-modal-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    height: 100%;
    overflow-y: scroll;
  }
  @media ${viewport.desktop} {
    height: calc(100vh - 180px);
  }

  @media ${viewport.tablet} {
    top: 0;
    height: calc(100vh - 32px);
    margin: 16px;
  }

  @media ${viewport.smartphone} {
    top: 0;
    height: calc(100vh - 32px);
    margin: 16px;
  }
`;

const MessageContainer = styled.div<{ isDesktop: boolean }>`
  overflow-y: scroll;
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type Props = {
  notification: TransformNotification;
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
  onClose: () => void;
};

export const DetailModal = memo<Props>(
  ({ notification, onClose, accountAccessibleCompanies, accountAccessibleShops }) => {
    const { isDesktop } = useViewport();

    return (
      <StyledModal
        zIndex={1003}
        open={Boolean(notification)}
        title={
          <HeaderContainer>
            <Category isImportant={notification.isImportant} category={notification.category} />
            <Title
              title={notification.title}
              fontSize={16}
              fontWeight={notification.isUnread ? "bold" : "normal"}
            />
            <Targets
              targets={notification.targets}
              accountAccessibleCompanies={accountAccessibleCompanies}
              accountAccessibleShops={accountAccessibleShops}
            />
          </HeaderContainer>
        }
        onCancel={onClose}
        width={isDesktop ? "800px" : "calc(100vw - 32px)"}
        isDesktop={isDesktop}
        footer={<AvailableFrom availableFrom={notification.availableFrom} />}
      >
        <MessageContainer isDesktop={isDesktop}>
          <Message message={notification.message} />
        </MessageContainer>
      </StyledModal>
    );
  },
);
