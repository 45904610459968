import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetAdyenTerminalPaymentOnSitePaymentDetailTypes = gql`
    query GetAdyenTerminalPaymentOnSitePaymentDetailTypes($companyId: uuid!) {
  adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}}
  ) {
    id
    adyenTerminalPaymentMethodType
    onSitePaymentDetailTypeId
  }
}
    `;
export const GetOnSitePaymentDetailTypes = gql`
    query GetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {company: {id: {_eq: $companyId}}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;
export const OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailType = gql`
    mutation OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailType($companyId: uuid!, $adyenTerminalPaymentMethodType: adyenTerminalPaymentMethodType_enum!, $objects: [adyenTerminalPaymentOnSitePaymentDetailType_insert_input!]!) {
  delete_adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, adyenTerminalPaymentMethodType: {_eq: $adyenTerminalPaymentMethodType}}
  ) {
    affected_rows
  }
  insert_adyenTerminalPaymentOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export const OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailType = gql`
    mutation OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailType($companyId: uuid!, $adyenTerminalPaymentMethodType: adyenTerminalPaymentMethodType_enum!) {
  delete_adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, adyenTerminalPaymentMethodType: {_eq: $adyenTerminalPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { adyenTerminalPaymentOnSitePaymentDetailType: Array<(
    { __typename?: 'adyenTerminalPaymentOnSitePaymentDetailType' }
    & Pick<Types.AdyenTerminalPaymentOnSitePaymentDetailType, 'id' | 'adyenTerminalPaymentMethodType' | 'onSitePaymentDetailTypeId'>
  )> }
);

export type GetOnSitePaymentDetailTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type GetOnSitePaymentDetailTypesQuery = (
  { __typename?: 'query_root' }
  & { onSitePaymentDetailType: Array<(
    { __typename?: 'onSitePaymentDetailType' }
    & Pick<Types.OnSitePaymentDetailType, 'id' | 'type' | 'label'>
  )> }
);

export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  adyenTerminalPaymentMethodType: Types.AdyenTerminalPaymentMethodTypeEnum;
  objects: Array<Types.AdyenTerminalPaymentOnSitePaymentDetailTypeInsertInput> | Types.AdyenTerminalPaymentOnSitePaymentDetailTypeInsertInput;
}>;


export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_adyenTerminalPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'adyenTerminalPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.AdyenTerminalPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )>, insert_adyenTerminalPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'adyenTerminalPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.AdyenTerminalPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);

export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  adyenTerminalPaymentMethodType: Types.AdyenTerminalPaymentMethodTypeEnum;
}>;


export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_adyenTerminalPaymentOnSitePaymentDetailType?: Types.Maybe<(
    { __typename?: 'adyenTerminalPaymentOnSitePaymentDetailType_mutation_response' }
    & Pick<Types.AdyenTerminalPaymentOnSitePaymentDetailTypeMutationResponse, 'affected_rows'>
  )> }
);


export const GetAdyenTerminalPaymentOnSitePaymentDetailTypesDocument = gql`
    query GetAdyenTerminalPaymentOnSitePaymentDetailTypes($companyId: uuid!) {
  adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}}
  ) {
    id
    adyenTerminalPaymentMethodType
    onSitePaymentDetailTypeId
  }
}
    `;

/**
 * __useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery, GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery, GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables>(GetAdyenTerminalPaymentOnSitePaymentDetailTypesDocument, options);
      }
export function useGetAdyenTerminalPaymentOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery, GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery, GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables>(GetAdyenTerminalPaymentOnSitePaymentDetailTypesDocument, options);
        }
export type GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useGetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery>;
export type GetAdyenTerminalPaymentOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useGetAdyenTerminalPaymentOnSitePaymentDetailTypesLazyQuery>;
export type GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<GetAdyenTerminalPaymentOnSitePaymentDetailTypesQuery, GetAdyenTerminalPaymentOnSitePaymentDetailTypesQueryVariables>;
export const GetOnSitePaymentDetailTypesDocument = gql`
    query GetOnSitePaymentDetailTypes($companyId: uuid!) {
  onSitePaymentDetailType(
    where: {company: {id: {_eq: $companyId}}, isArchived: {_eq: false}}
  ) {
    id
    type
    label
  }
}
    `;

/**
 * __useGetOnSitePaymentDetailTypesQuery__
 *
 * To run a query within a React component, call `useGetOnSitePaymentDetailTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnSitePaymentDetailTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnSitePaymentDetailTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetOnSitePaymentDetailTypesQuery(baseOptions: Apollo.QueryHookOptions<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>(GetOnSitePaymentDetailTypesDocument, options);
      }
export function useGetOnSitePaymentDetailTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>(GetOnSitePaymentDetailTypesDocument, options);
        }
export type GetOnSitePaymentDetailTypesQueryHookResult = ReturnType<typeof useGetOnSitePaymentDetailTypesQuery>;
export type GetOnSitePaymentDetailTypesLazyQueryHookResult = ReturnType<typeof useGetOnSitePaymentDetailTypesLazyQuery>;
export type GetOnSitePaymentDetailTypesQueryResult = Apollo.QueryResult<GetOnSitePaymentDetailTypesQuery, GetOnSitePaymentDetailTypesQueryVariables>;
export const OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeDocument = gql`
    mutation OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailType($companyId: uuid!, $adyenTerminalPaymentMethodType: adyenTerminalPaymentMethodType_enum!, $objects: [adyenTerminalPaymentOnSitePaymentDetailType_insert_input!]!) {
  delete_adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, adyenTerminalPaymentMethodType: {_eq: $adyenTerminalPaymentMethodType}}
  ) {
    affected_rows
  }
  insert_adyenTerminalPaymentOnSitePaymentDetailType(objects: $objects) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation, { data, loading, error }] = useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      adyenTerminalPaymentMethodType: // value for 'adyenTerminalPaymentMethodType'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationVariables>(OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeDocument, options);
      }
export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useOnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesUpdateAdyenTerminalOnSitePaymentDetailTypeMutationVariables>;
export const OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeDocument = gql`
    mutation OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailType($companyId: uuid!, $adyenTerminalPaymentMethodType: adyenTerminalPaymentMethodType_enum!) {
  delete_adyenTerminalPaymentOnSitePaymentDetailType(
    where: {companyId: {_eq: $companyId}, adyenTerminalPaymentMethodType: {_eq: $adyenTerminalPaymentMethodType}}
  ) {
    affected_rows
  }
}
    `;
export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationFn = Apollo.MutationFunction<OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationVariables>;

/**
 * __useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation__
 *
 * To run a mutation, you first call `useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation, { data, loading, error }] = useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      adyenTerminalPaymentMethodType: // value for 'adyenTerminalPaymentMethodType'
 *   },
 * });
 */
export function useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation(baseOptions?: Apollo.MutationHookOptions<OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationVariables>(OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeDocument, options);
      }
export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationHookResult = ReturnType<typeof useOnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationResult = Apollo.MutationResult<OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation>;
export type OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationOptions = Apollo.BaseMutationOptions<OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutation, OnSitePaymentDetailTypesDeleteAdyenTerminalPaymentOnSitePaymentDetailTypeMutationVariables>;