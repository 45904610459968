import React, { memo } from "react";
import { Tooltip } from "antd";
import { formatPrice } from "util/price";
import { isNotNull } from "util/type/primitive";

import { EditIcon } from "components/ColorIcon/EditIcon";
import { IconLink } from "components/IconLink";
import { Table } from "components/Table";
import { useIsFeatureEnabled } from "hooks/useIsFeatureEnabled";
import { CashManagement } from "pages/ShopDailyCashRegisterBalancing/types";

type Props = {
  shopId: string;
  date: string;
  index: number;
  loading?: boolean;
  cashManagement?: CashManagement;
};

export const CashManagementTable = memo<Props>(
  ({ shopId, date, loading, cashManagement, index }) => {
    const { isFeatureEnabled } = useIsFeatureEnabled();

    const { remark } = cashManagement ?? {};

    const typeAndAmounts = [
      {
        type: "釣銭準備金",
        amount: cashManagement?.previousReserveCashAmount,
      },
      {
        type: "現金売上",
        amount: cashManagement?.cashSales,
      },
      {
        type: "入金額計",
        amount: cashManagement?.depositAmount,
      },
      {
        type: "出金額計",
        amount: cashManagement?.withdrawalAmount,
      },
      {
        type: "現金在高",
        amount: cashManagement?.cashAmount,
      },
      {
        type: "実現金在高",
        amount: cashManagement?.actualCashAmount,
      },
      {
        type: "現金過不足",
        amount: cashManagement?.cashDiscrepancies,
      },
      {
        type: "銀行預入金",
        amount: cashManagement?.bankDepositAmount,
      },
      {
        type: "繰越準備金",
        amount: cashManagement?.carriedForwardReserveCashAmount,
      },
    ] as const;

    const columns = [
      {
        title: "金種",
        dataIndex: "type",
      },
      {
        title: "金額",
        align: "right",
        render(_: unknown, { amount }: (typeof typeAndAmounts)[number]) {
          return `${typeof amount === "number" ? formatPrice(amount) : "-"}`;
        },
      } as const,
      isFeatureEnabled("editBankDepositAmount")
        ? ({
            title: "",
            width: 60,
            align: "center",
            fixed: "right",
            render(_: unknown, { type }: (typeof typeAndAmounts)[number]) {
              return type === "銀行預入金" ? (
                <Tooltip title="銀行預入金額を修正">
                  <IconLink
                    to={`/dailyCashRegisterBalancing/${shopId}/${date}/bankDepositAmount/edit?index=${index}`}
                    data-cy="link-to-bank-deposit-amount-edit"
                  >
                    <EditIcon />
                  </IconLink>
                </Tooltip>
              ) : null;
            },
          } as const)
        : null,
    ].filter(isNotNull);

    return (
      <Table
        rowKey="type"
        columns={columns}
        dataSource={typeAndAmounts}
        loading={loading}
        bordered
        title={() => "現金管理"}
        footer={remark ? () => remark : undefined}
        pagination={false}
        data-cy="cash-management-table"
      />
    );
  },
);
