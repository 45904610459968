import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "antd";
import { ApolloError } from "@apollo/client";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopCashlessSettingHeader } from "components/PageHeader/ShopCashlessSettingHeader";

import { AddShopCashlessSettingMerchantCategoryFormValues } from "./AddShopCashlessSettingMerchantCategoryForm/useAddShopCashlessSettingMerchantCategoryForm";
import { AssignShopCashlessSettingTerminalIdFormValues } from "./AssignShopCashlessSettingTerminalIdForm/useAssignShopCashlessSettingTerminalIdForm";
import { AddShopCashlessSettingMerchantCategoryForm } from "./AddShopCashlessSettingMerchantCategoryForm";
import { AssignShopCashlessSettingTerminalIdForm } from "./AssignShopCashlessSettingTerminalIdForm";
import {
  useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation,
  useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation,
  useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery,
} from "./queries";

export const EditShopCashlessSettingProviderSetting = () => {
  const { id: shopId } = useParams<{ id: string }>();

  const { data, error, loading } =
    useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery(
      shopId ? { variables: { shopId } } : { skip: true },
    );
  const shop = useMemo(() => data?.shop_by_pk ?? null, [data]);
  const merchantCategory = useMemo(
    () => data?.adyenPaymentStoreMerchantCategory[0] ?? null,
    [data],
  );
  const terminalAssignmentHistory = useMemo(
    () => data?.adyenTerminalPaymentShopConfig[0]?.adyenTerminalAssignmentHistories[0] ?? null,
    [data],
  );

  const [
    createAdyenPaymentStoreMerchantCategory,
    { loading: loadingCreateAdyenPaymentStoreMerchantCategory },
  ] = useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation();

  const [
    assignShopCashlessSettingTerminalId,
    { loading: loadingAssignShopCashlessSettingTerminalId },
  ] = useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation();

  const onSubmitMerchantCategoryForm = useCallback(
    async (values: AddShopCashlessSettingMerchantCategoryFormValues) => {
      if (!shopId) return;

      try {
        await createAdyenPaymentStoreMerchantCategory({
          variables: {
            input: { ...values, shopId },
          },
        });
        message.success("作成に成功しました");
      } catch (e) {
        if (e instanceof ApolloError) {
          message.error(e.message);
          return;
        }
        message.error("作成に失敗しました");
      }
    },
    [shopId, createAdyenPaymentStoreMerchantCategory],
  );

  const onSubmitTerminalIdForm = useCallback(
    async (values: AssignShopCashlessSettingTerminalIdFormValues) => {
      if (!shopId) return;

      try {
        await assignShopCashlessSettingTerminalId({
          variables: {
            input: { shopId, terminalId: `${values.model}-${values.serialNumber}` },
          },
        });
        message.success("登録に成功しました");
      } catch (e) {
        if (e instanceof ApolloError) {
          message.error(e.message);
          return;
        }
        message.error("登録に失敗しました");
      }
    },
    [shopId, assignShopCashlessSettingTerminalId],
  );

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name ?? ""}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "決済店舗" }],
      }}
    >
      <ShopCashlessSettingHeader shop={shop} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      {shop && (
        <>
          <AddShopCashlessSettingMerchantCategoryForm
            merchantCategory={merchantCategory ?? null}
            loading={loadingCreateAdyenPaymentStoreMerchantCategory}
            onSubmit={onSubmitMerchantCategoryForm}
            onFormValidationError={onFormValidationError}
          />
          <AssignShopCashlessSettingTerminalIdForm
            initialAdyenTerminalAssignmentHistory={terminalAssignmentHistory}
            loading={loadingAssignShopCashlessSettingTerminalId}
            onSubmit={onSubmitTerminalIdForm}
            onFormValidationError={onFormValidationError}
          />
        </>
      )}
    </DashboardLayout>
  );
};
