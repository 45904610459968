import { onError } from "@apollo/client/link/error";
import { GraphQLError } from "graphql";

import { message } from "components/antd/message";
import { isAPIError } from "libs/isApiError";
import { logger } from "libs/logger";
import { TypedGraphQLErrorExtensions } from "types/graphqlErrorExtensions";

export const createErrorLink = () =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        const { message: errorMessage, path, ...rest } = graphQLError;
        const extensions = graphQLError.extensions as TypedGraphQLErrorExtensions;

        if (isAPIError(graphQLError as GraphQLError)) {
          logger.error("API Error:", extensions?.exception.response);
        } else {
          logger.critical(errorMessage, { ...rest, path, extensions });
        }
      });

      message.error("エラーが発生しました");
    }

    if (networkError) console.error("[Network error]: ", networkError);
  });
