import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { parseTextsAndUrls } from "util/parseTextsAndUrls";

type Props = {
  message: string;
};

export const Message = memo<Props>(({ message }) => {
  const fragments = useMemo(() => parseTextsAndUrls(message), [message]);
  return (
    <>
      {fragments.map(({ type, value }, index) => {
        if (type === "text") {
          return <p key={index}>{value}</p>;
        }
        if (type === "url" && value) {
          return (
            <Link target="_blank" key={index} to={value}>
              {value}
            </Link>
          );
        }
        if (type === "lineBreak") {
          return <br key={index} />;
        }

        return null;
      })}
    </>
  );
});
