import { useEffect, useMemo, useState } from "react";
import {
  collection,
  FirestoreError,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { chunk, uniqBy } from "lodash";

import { AccessibleCompanies, AccessibleShops } from "components/Layout/DashboardLayout/types";
import { firestore } from "libs/firebase";

export type NotificationLabelType = "important";

export type NotificationTargetKey =
  | "broadcast"
  | NotificationTargetCompanyKey
  | NotificationTargetShopKey;
export type NotificationTargetCompanyKey = `company:${string}`;
export type NotificationTargetShopKey = `shop:${string}`;

export type NotificationCategory = "highest" | "high" | "middle" | "low";

export type NotificationChannelType = "handy" | "cashRegister" | "dashboard";

export type Notification = {
  id: string;
  title: string;
  labels: NotificationLabelType[];
  message: string;
  category: NotificationCategory;
  targets: NotificationTargetKey[];
  channels: NotificationChannelType[];
  availableFrom: Timestamp;
  availableUntil: Timestamp;
  updatedAt: Timestamp;
  published: boolean;
};

export const isNotificationTargetCompanyKey = (
  key: NotificationTargetKey,
): key is NotificationTargetCompanyKey => key.startsWith("company");
export const isNotificationTargetShopKey = (
  key: NotificationTargetKey,
): key is NotificationTargetShopKey => key.startsWith("shop");

export const notificationCategoryLabel: { [key in NotificationCategory]: string } = {
  highest: "障害・不具合情報",
  high: "リリース情報",
  middle: "メンテナンス情報",
  low: "お知らせ",
};

const MAX_TARGETS_SIZE = 30;

const getInitialNotificationQueries = (targets: string[], initialTimeStamp: Timestamp) =>
  // NOTE: array-contains-any の制限が 30 のため分割して query を作成する。
  chunk(targets, MAX_TARGETS_SIZE).map((targets) =>
    query(
      collection(firestore, "notifications"),
      where("targets", "array-contains-any", targets),
      where("published", "==", true),
      where("availableUntil", ">", initialTimeStamp),
      orderBy("availableUntil", "desc"),
      limit(50),
    ),
  );

export const useNotifications = ({
  accountAccessibleCompanies,
  accountAccessibleShops,
}: {
  accountAccessibleCompanies: AccessibleCompanies;
  accountAccessibleShops: AccessibleShops;
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [error, setError] = useState<FirestoreError | null>(null);

  const targets = useMemo(() => {
    const accessibleCompanyIds = accountAccessibleCompanies.map(
      (company) => `company:${company.company?.id}`,
    );
    const accessibleShopIds = accountAccessibleShops.map((shop) => `shop:${shop.shop.shopId}`);

    return ["broadcast", ...accessibleCompanyIds, ...accessibleShopIds];
  }, [accountAccessibleCompanies, accountAccessibleShops]);

  useEffect(() => {
    const initialTimeStamp = Timestamp.now();
    const queries = getInitialNotificationQueries(targets, initialTimeStamp);
    (async () => {
      const notifications: Notification[] = [];

      for (const query of queries) {
        const querySnapshot = await getDocs(query);
        const newNotifications = querySnapshot.docs
          .map((doc) => doc.data() as Notification)
          .filter(
            ({ availableFrom, availableUntil, channels }) =>
              channels.includes("dashboard") &&
              availableFrom <= initialTimeStamp &&
              availableUntil > initialTimeStamp,
          );
        notifications.push(...newNotifications);
      }

      setNotifications((prev) =>
        uniqBy([...prev, ...notifications], ({ id }) => id).sort(
          (a, b) => b.availableFrom.seconds - a.availableFrom.seconds,
        ),
      );
    })();

    const unsubscribeList = queries.map((_query) =>
      onSnapshot(
        _query,
        (snapshot) => {
          const snapSortTimeStamp = Timestamp.now();
          const _notifications = snapshot
            .docChanges()
            .filter((change) => change.type === "added" || change.type === "modified")
            .map((doc) => doc.doc.data() as Notification)
            .filter(
              ({ availableFrom, availableUntil, channels }) =>
                channels.includes("dashboard") &&
                availableFrom <= snapSortTimeStamp &&
                availableUntil > snapSortTimeStamp,
            );

          setNotifications((prev) => {
            const acc = new Map(prev.map((notification) => [notification.id, notification]));
            for (const notification of _notifications) {
              acc.set(notification.id, notification);
            }
            return Array.from(acc.values()).sort(
              (a, b) => b.availableFrom.seconds - a.availableFrom.seconds,
            );
          });
        },
        (error) => {
          setError(error);
        },
      ),
    );

    return () => unsubscribeList.forEach((unsubscribe) => unsubscribe());
  }, [targets]);

  return {
    notifications,
    error,
  };
};
